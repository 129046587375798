export function saveCr1Request(data) {
  return {
    type: '@cr1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCr1Success() {
  return {
    type: '@cr1/SAVE_SUCCESS',
  }
}

export function saveCr1Failure() {
  return {
    type: '@cr1/SAVE_FAILURE',
  }
}

export function updateCr1Request(data) {
  return {
    type: '@cr1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCr1Success() {
  return {
    type: '@cr1/UPDATE_SUCCESS',
  }
}

export function updateCr1Failure() {
  return {
    type: '@cr1/UPDATE_FAILURE',
  }
}