export function saveRemaRequest(data) {
  return {
    type: '@rema/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveRemaSuccess() {
  return {
    type: '@rema/SAVE_SUCCESS',
  }
}

export function saveRemaFailure() {
  return {
    type: '@rema/SAVE_FAILURE',
  }
}

export function updateRemaRequest(data) {
  return {
    type: '@rema/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateRemaSuccess() {
  return {
    type: '@rema/UPDATE_SUCCESS',
  }
}

export function updateRemaFailure() {
  return {
    type: '@rema/UPDATE_FAILURE',
  }
}