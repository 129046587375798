import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import history from "~/services/history";
import api from "~/services/api";

import {
  saveLr2Failure,
  saveLr2Success,
  updateLr2Failure,
  updateLr2Success
} from "./actions";

export function* saveLr2({ payload }) {
  try {
    yield call(api.post, "api/lr2", payload.data, { withCredentials: true });

    yield put(saveLr2Success());

    toast.success("Lr2 criado com sucesso!");

    history.push("/specifications/pilar3/resources/lr2");
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveLr2Failure());
  }
}

export function* updateLr2({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/lr2/${id}`, payload.data, { withCredentials: true });

    yield put(updateLr2Success());

    toast.success("Lr2 atualizado com sucesso!");

    history.push("/specifications/pilar3/resources/lr2");
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateLr2Failure());
  }
}

export default all([
  takeLatest("@lr2/SAVE_REQUEST", saveLr2),
  takeLatest("@lr2/UPDATE_REQUEST", updateLr2)
]);
