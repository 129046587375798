import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { saveBalanceSheetFailure, saveBalanceSheetSuccess } from './actions';

export function* saveBalanceSheet({ payload }) {
  try {
    yield call(api.post, `api/balance-sheet`, payload.data, { withCredentials: true });

    yield put(saveBalanceSheetSuccess());

    toast.success('Balanço patrimonial atualizado com sucesso!');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveBalanceSheetFailure());
  }
}

export default all([
  takeLatest('@balanceSheet/SAVE_REQUEST', saveBalanceSheet),
]);