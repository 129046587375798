export function saveMr1Request(data) {
  return {
    type: '@mr1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveMr1Success() {
  return {
    type: '@mr1/SAVE_SUCCESS',
  }
}

export function saveMr1Failure() {
  return {
    type: '@mr1/SAVE_FAILURE',
  }
}

export function updateMr1Request(data) {
  return {
    type: '@mr1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateMr1Success() {
  return {
    type: '@mr1/UPDATE_SUCCESS',
  }
}

export function updateMr1Failure() {
  return {
    type: '@mr1/UPDATE_FAILURE',
  }
}