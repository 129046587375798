import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { updateOrganizationSuccess, updateOrganizationFailure } from './actions';

export function* updateOrganization({ payload }) {
  try {
    const { id } = payload.data;
    const response = yield call(api.post, `api/organization/${id}`, payload.data, { withCredentials: true });

    toast.success('Instituição atualizada com sucesso!')

    yield put(updateOrganizationSuccess(response.data));
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateOrganizationFailure());
  }
}

export default all([
  takeLatest('@organization/UPDATE_REQUEST', updateOrganization)
]);