import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCcr3Failure, saveCcr3Success, updateCcr3Failure, updateCcr3Success } from './actions';

export function* saveCcr3({ payload }) {
  try {
    yield call(api.post, 'api/ccr3', payload.data, { withCredentials: true });

    yield put(saveCcr3Success());

    toast.success('CCR3 criado com sucesso!')

    history.push('/specifications/pilar3/resources/ccr3');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCcr3Failure());
  }
}

export function* updateCcr3({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/ccr3/${id}`, payload.data, { withCredentials: true });

    yield put(updateCcr3Success());

    toast.success('CCR3 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/ccr3');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCcr3Failure());
  }
}

export default all([
  takeLatest('@ccr3/SAVE_REQUEST', saveCcr3),
  takeLatest('@ccr3/UPDATE_REQUEST', updateCcr3)
]);