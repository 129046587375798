export function saveSec2Request(data) {
  return {
    type: '@sec2/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveSec2Success() {
  return {
    type: '@sec2/SAVE_SUCCESS',
  }
}

export function saveSec2Failure() {
  return {
    type: '@sec2/SAVE_FAILURE',
  }
}

export function updateSec2Request(data) {
  return {
    type: '@sec2/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateSec2Success() {
  return {
    type: '@sec2/UPDATE_SUCCESS',
  }
}

export function updateSec2Failure() {
  return {
    type: '@sec2/UPDATE_FAILURE',
  }
}