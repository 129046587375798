export function saveCc2Request(data) {
  return {
    type: '@cc2/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCc2Success() {
  return {
    type: '@cc2/SAVE_SUCCESS',
  }
}

export function saveCc2Failure() {
  return {
    type: '@cc2/SAVE_FAILURE',
  }
}

export function updateCc2Request(data) {
  return {
    type: '@cc2/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCc2Success() {
  return {
    type: '@cc2/UPDATE_SUCCESS',
  }
}

export function updateCc2Failure() {
  return {
    type: '@cc2/UPDATE_FAILURE',
  }
}