import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { saveIncomeStatementEmbracingFailure, saveIncomeStatementEmbracingSuccess } from './actions';

export function* saveIncomeStatementEmbracing({ payload }) {
  try {
    yield call(api.post, `api/income-statement-embracing`, payload.data, { withCredentials: true });

    yield put(saveIncomeStatementEmbracingSuccess());

    toast.success('Demonstração do resultado abrangente atualizado com sucesso!');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveIncomeStatementEmbracingFailure());
  }
}

export default all([
  takeLatest('@incomeStatementEmbracing/SAVE_REQUEST', saveIncomeStatementEmbracing),
]);