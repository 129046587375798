import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveOv1Failure, saveOv1Success, updateOv1Failure, updateOv1Success } from './actions';

export function* saveOv1({ payload }) {
  try {
    yield call(api.post, 'api/ov1', payload.data, { withCredentials: true });

    yield put(saveOv1Success());

    toast.success('Ov1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/ov1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveOv1Failure());
  }
}

export function* updateOv1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/ov1/${id}`, payload.data, { withCredentials: true });

    yield put(updateOv1Success());

    toast.success('Ov1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/ov1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateOv1Failure());
  }
}

export default all([
  takeLatest('@ov1/SAVE_REQUEST', saveOv1),
  takeLatest('@ov1/UPDATE_REQUEST', updateOv1)
]);