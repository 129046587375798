export function savePv1Request(data) {
  return {
    type: '@pv1/SAVE_REQUEST',
    payload: { data },
  }
}

export function savePv1Success() {
  return {
    type: '@pv1/SAVE_SUCCESS',
  }
}

export function savePv1Failure() {
  return {
    type: '@pv1/SAVE_FAILURE',
  }
}

export function updatePv1Request(data) {
  return {
    type: '@pv1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updatePv1Success() {
  return {
    type: '@pv1/UPDATE_SUCCESS',
  }
}

export function updatePv1Failure() {
  return {
    type: '@pv1/UPDATE_FAILURE',
  }
}