import React from 'react';
import PropTypes from 'prop-types';
import {App,Wrapper, WrapperColumn} from './styles';

export default function ViewLayout({ children }) {
    return (
        <App>
            <Wrapper>
                <WrapperColumn>
                    {children}
                </WrapperColumn>
            </Wrapper>
        </App>
    )
}

ViewLayout.propTypes = {
    children: PropTypes.element.isRequired,
}