export function saveSec4Request(data) {
  return {
    type: '@sec4/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveSec4Success() {
  return {
    type: '@sec4/SAVE_SUCCESS',
  }
}

export function saveSec4Failure() {
  return {
    type: '@sec4/SAVE_FAILURE',
  }
}

export function updateSec4Request(data) {
  return {
    type: '@sec4/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateSec4Success() {
  return {
    type: '@sec4/UPDATE_SUCCESS',
  }
}

export function updateSec4Failure() {
  return {
    type: '@sec4/UPDATE_FAILURE',
  }
}