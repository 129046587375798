import { Ability, AbilityBuilder } from "@casl/ability";
import { store } from '~/store';

const ability = new Ability();

function can(action, subject) {
    return ability.can(action, subject);
}

function initialize() {
    updateAbility();

    store.subscribe(() => {
        updateAbility();
    });
}

function updateAbility() {
    let user = store.getState().user;
    ability.update(defineRulesFor(user));
}

const defineRulesFor = (user) => {
    const permissions = user.profile?.permissions;
    const { can, rules } = new AbilityBuilder();

    // This logic depends on how the
    // server sends you the permissions array
    if (permissions) {
        permissions.forEach((p) => {
            let per = p.split("_");
            can(per[0], per[1]);
        });
    }

    return rules;
};

export { can, initialize };