import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveDocumentFailure, saveDocumentSuccess, updateDocumentFailure, updateDocumentSuccess } from './actions';

export function* saveDocument({ payload }) {
  try {
    yield call(api.post, 'api/document', payload.data, { withCredentials: true });

    yield put(saveDocumentSuccess());

    toast.success('Documento criado com sucesso!')

    history.push('/specifications/demonstrativo/resources/document');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveDocumentFailure());
  }
}

export function* updateDocument({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/document/${id}`, payload.data, { withCredentials: true });

    yield put(updateDocumentSuccess());

    toast.success('Documento atualizado com sucesso!')

    history.push(`/specifications/demonstrativo/resources/document/${id}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateDocumentFailure());
  }
}

export default all([
  takeLatest('@document/SAVE_REQUEST', saveDocument),
  takeLatest('@document/UPDATE_REQUEST', updateDocument)
]);