import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveSec3Failure, saveSec3Success, updateSec3Failure, updateSec3Success } from './actions';

export function* saveSec3({ payload }) {
  try {
    yield call(api.post, 'api/sec3', payload.data, { withCredentials: true });

    yield put(saveSec3Success());

    toast.success('SEC3 criado com sucesso!')

    history.push('/specifications/pilar3/resources/sec3');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveSec3Failure());
  }
}

export function* updateSec3({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/sec3/${id}`, payload.data, { withCredentials: true });

    yield put(updateSec3Success());

    toast.success('SEC3 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/sec3');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateSec3Failure());
  }
}

export default all([
  takeLatest('@sec3/SAVE_REQUEST', saveSec3),
  takeLatest('@sec3/UPDATE_REQUEST', updateSec3)
]);