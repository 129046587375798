import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import DefaultLayout from '~/pages/_layouts/default';

import { store } from '~/store';
import { can } from '~/services/can';

import Unauthorized from "~/components/Unauthorized";

export default function PrivateRoute({
  component: Component,
  permission,
  ...rest
}) {
  const { signed, loading } = store.getState().auth;

  return <Route
    {...rest}
    render={(props) => {
      if (loading) {
        return <h2>Carregando...</h2>;
      }

      if (!signed) {
        return <Redirect to="/login" />;
      }

      if (permission) {
        const _permission = permission.split("_");
        if (!can(_permission[0], _permission[1])) {
          return (
            <Unauthorized />
          )
        }
      }

      return (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )
    }}
  />
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}