export function saveRoleRequest(data) {
  return {
    type: '@role/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveRoleSuccess() {
  return {
    type: '@role/SAVE_SUCCESS',
  }
}

export function saveRoleFailure() {
  return {
    type: '@role/SAVE_FAILURE',
  }
}

export function updateRoleRequest(data) {
  return {
    type: '@role/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateRoleSuccess() {
  return {
    type: '@role/UPDATE_SUCCESS',
  }
}

export function updateRoleFailure() {
  return {
    type: '@role/UPDATE_FAILURE',
  }
}