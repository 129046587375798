export function saveOv1Request(data) {
  return {
    type: '@ov1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveOv1Success() {
  return {
    type: '@ov1/SAVE_SUCCESS',
  }
}

export function saveOv1Failure() {
  return {
    type: '@ov1/SAVE_FAILURE',
  }
}

export function updateOv1Request(data) {
  return {
    type: '@ov1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateOv1Success() {
  return {
    type: '@ov1/UPDATE_SUCCESS',
  }
}

export function updateOv1Failure() {
  return {
    type: '@ov1/UPDATE_FAILURE',
  }
}