export function saveSecaRequest(data) {
  return {
    type: '@seca/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveSecaSuccess() {
  return {
    type: '@seca/SAVE_SUCCESS',
  }
}

export function saveSecaFailure() {
  return {
    type: '@seca/SAVE_FAILURE',
  }
}

export function updateSecaRequest(data) {
  return {
    type: '@seca/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateSecaSuccess() {
  return {
    type: '@seca/UPDATE_SUCCESS',
  }
}

export function updateSecaFailure() {
  return {
    type: '@seca/UPDATE_FAILURE',
  }
}