export function saveGsib1Request(data) {
  return {
    type: '@gsib1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveGsib1Success() {
  return {
    type: '@gsib1/SAVE_SUCCESS',
  }
}

export function saveGsib1Failure() {
  return {
    type: '@gsib1/SAVE_FAILURE',
  }
}

export function updateGsib1Request(data) {
  return {
    type: '@gsib1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateGsib1Success() {
  return {
    type: '@gsib1/UPDATE_SUCCESS',
  }
}

export function updateGsib1Failure() {
  return {
    type: '@gsib1/UPDATE_FAILURE',
  }
}