import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { saveEquityChangeStatementFailure, saveEquityChangeStatementSuccess } from './actions';

export function* saveEquityChangeStatement({ payload }) {
  try {
    yield call(api.post, `api/equity-change-statement`, payload.data, { withCredentials: true });

    yield put(saveEquityChangeStatementSuccess());

    toast.success('Demonstração das mutações do patrimônio líquido atualizado com sucesso!');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveEquityChangeStatementFailure());
  }
}

export default all([
  takeLatest('@equityChangeStatement/SAVE_REQUEST', saveEquityChangeStatement),
]);