import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { updateEmailSuccess, updateEmailFailure } from './actions';

export function* updateEmail({ payload }) {
  try {
    
    yield call(api.post, 'api/configuracoes/email', payload.data, { withCredentials: true });

    yield put(updateEmailSuccess());

    toast.success('Configuração de e-mail atualizada com sucesso!')
    
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateEmailFailure());
  }
}

export default all([
  takeLatest('@email/UPDATE_REQUEST', updateEmail)
]);