import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import './config/ReactotronConfig';
import './config/yup.locale.pt-br';

import Routes from './routes';
import history from './services/history';

import { store, persistor } from './store';

import ScrollToTop from './components/ScrollToTop';

import GlobalStyle from './styles/global';

import { initialize } from '~/services/can';

const onBeforeLift = async () => {
  initialize();
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        onBeforeLift={onBeforeLift}
        persistor={persistor}>
        <Router history={history}>
          <ScrollToTop />
          <Routes />
          <GlobalStyle />
          <ToastContainer autoClose={3000} />
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App;
