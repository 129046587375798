import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import history from "~/services/history";
import api from "~/services/api";

import {
  saveLiq2Failure,
  saveLiq2Success,
  updateLiq2Failure,
  updateLiq2Success
} from "./actions";

export function* saveLiq2({ payload }) {
  try {
    yield call(api.post, "api/liq2", payload.data, { withCredentials: true });

    yield put(saveLiq2Success());

    toast.success("Liq2 criado com sucesso!");

    history.push("/specifications/pilar3/resources/liq2");
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveLiq2Failure());
  }
}

export function* updateLiq2({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/liq2/${id}`, payload.data, { withCredentials: true });

    yield put(updateLiq2Success());

    toast.success("Liq2 atualizado com sucesso!");

    history.push("/specifications/pilar3/resources/liq2");
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateLiq2Failure());
  }
}

export default all([
  takeLatest("@liq2/SAVE_REQUEST", saveLiq2),
  takeLatest("@liq2/UPDATE_REQUEST", updateLiq2)
]);
