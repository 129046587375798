export function saveIrrbb1Request(data) {
  return {
    type: '@irrbb1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveIrrbb1Success() {
  return {
    type: '@irrbb1/SAVE_SUCCESS',
  }
}

export function saveIrrbb1Failure() {
  return {
    type: '@irrbb1/SAVE_FAILURE',
  }
}

export function updateIrrbb1Request(data) {
  return {
    type: '@irrbb1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateIrrbb1Success() {
  return {
    type: '@irrbb1/UPDATE_SUCCESS',
  }
}

export function updateIrrbb1Failure() {
  return {
    type: '@irrbb1/UPDATE_FAILURE',
  }
}