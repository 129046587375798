export function saveEquityChangeStatementRequest(data) {
  return {
    type: '@equityChangeStatement/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveEquityChangeStatementSuccess() {
  return {
    type: '@equityChangeStatement/SAVE_SUCCESS',
  }
}

export function saveEquityChangeStatementFailure() {
  return {
    type: '@equityChangeStatement/SAVE_FAILURE',
  }
}