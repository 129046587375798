import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCcr8Failure, saveCcr8Success, updateCcr8Failure, updateCcr8Success } from './actions';

export function* saveCcr8({ payload }) {
  try {
    yield call(api.post, 'api/ccr8', payload.data, { withCredentials: true });

    yield put(saveCcr8Success());

    toast.success('CCR8 criado com sucesso!')

    history.push('/specifications/pilar3/resources/ccr8');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCcr8Failure());
  }
}

export function* updateCcr8({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/ccr8/${id}`, payload.data, { withCredentials: true });

    yield put(updateCcr8Success());

    toast.success('CCR8 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/ccr8');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCcr8Failure());
  }
}

export default all([
  takeLatest('@ccr8/SAVE_REQUEST', saveCcr8),
  takeLatest('@ccr8/UPDATE_REQUEST', updateCcr8)
]);