import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCr1Failure, saveCr1Success, updateCr1Failure, updateCr1Success } from './actions';

export function* saveCr1({ payload }) {
  try {
    yield call(api.post, 'api/cr1', payload.data, { withCredentials: true });

    yield put(saveCr1Success());

    toast.success('CR1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/cr1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCr1Failure());
  }
}

export function* updateCr1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/cr1/${id}`, payload.data, { withCredentials: true });

    yield put(updateCr1Success());

    toast.success('CR1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/cr1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCr1Failure());
  }
}

export default all([
  takeLatest('@cr1/SAVE_REQUEST', saveCr1),
  takeLatest('@cr1/UPDATE_REQUEST', updateCr1)
]);