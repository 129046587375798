import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveRem2Failure, saveRem2Success, updateRem2Failure, updateRem2Success } from './actions';

export function* saveRem2({ payload }) {
  try {
    yield call(api.post, 'api/rem2', payload.data, { withCredentials: true });

    yield put(saveRem2Success());

    toast.success('REM2 criado com sucesso!')

    history.push('/specifications/pilar3/resources/rem2');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveRem2Failure());
  }
}

export function* updateRem2({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/rem2/${id}`, payload.data, { withCredentials: true });

    yield put(updateRem2Success());

    toast.success('REM2 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/rem2');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateRem2Failure());
  }
}

export default all([
  takeLatest('@rem2/SAVE_REQUEST', saveRem2),
  takeLatest('@rem2/UPDATE_REQUEST', updateRem2)
]);