import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { signInSuccess, signFailure, signUpSuccess, signInSuccessChallenge } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, 'api/auth/signin', {
      email,
      password
    });

    const { accessToken, user } = response.data;

    if (user.mustChangePassword) {
      yield put(signInSuccessChallenge());

      history.push({
        pathname: '/change-password-challenge',
        userId: user.id,
      });
    } else {
      yield put(signInSuccess(accessToken, user));

      history.push('/');
    }
  } catch (error) {
    toast.error('Falha na autenticação, verifique seus dados.')
    yield put(signFailure());
  }
}

export function signOut() {
  history.push('/');
}

export function* signUp({ payload }) {
  try {
    const { name, email, password, roleId, askForPasswordChangeNextLogin, fromSignUp } = payload;

    yield call(api.post, 'api/auth/signup', {
      name,
      email,
      password,
      roleId,
      askForPasswordChangeNextLogin,
    });

    yield put(signUpSuccess());

    toast.success('Conta criada com sucesso.')

    if (fromSignUp) {
      history.push('/');
    } else {
      history.push('/settings/organization/users');
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      toast.error(error.response.data.message);
    } else {
      toast.error('Erro ao criar conta, verifique seus dados.')
    }
    yield put(signFailure());
  }
}

export function* changePasswordSignIn({ payload }) {
  try {
    const response = yield call(api.post, 'api/auth/changePasswordSignIn', payload.data);

    const { accessToken, user } = response.data;

    if (user.mustChangePassword) {
      yield put(signInSuccessChallenge());

      history.push({
        pathname: '/change-password-challenge',
        userId: user.id,
      });
    } else {
      yield put(signInSuccess(accessToken, user));

      history.push('/');
    }
  } catch (error) {
    toast.error('Falha na autenticação, verifique seus dados.')
    yield put(signFailure());
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/CHANGE_PASSWORD_SIGN_IN_REQUEST', changePasswordSignIn),
]);