export function saveLr1Request(data) {
  return {
    type: "@lr1/SAVE_REQUEST",
    payload: { data }
  };
}

export function saveLr1Success() {
  return {
    type: "@lr1/SAVE_SUCCESS"
  };
}

export function saveLr1Failure() {
  return {
    type: "@lr1/SAVE_FAILURE"
  };
}

export function updateLr1Request(data) {
  return {
    type: "@lr1/UPDATE_REQUEST",
    payload: { data }
  };
}

export function updateLr1Success() {
  return {
    type: "@lr1/UPDATE_SUCCESS"
  };
}

export function updateLr1Failure() {
  return {
    type: "@lr1/UPDATE_FAILURE"
  };
}
