import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import history from "~/services/history";
import api from "~/services/api";

import {
  saveLiqaFailure,
  saveLiqaSuccess,
  updateLiqaFailure,
  updateLiqaSuccess
} from "./actions";

export function* saveLiqa({ payload }) {
  try {
    yield call(api.post, "api/liqa", payload.data, { withCredentials: true });

    yield put(saveLiqaSuccess());

    toast.success("Liqa criado com sucesso!");

    history.push("/specifications/pilar3/resources/liqa");
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveLiqaFailure());
  }
}

export function* updateLiqa({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/liqa/${id}`, payload.data, { withCredentials: true });

    yield put(updateLiqaSuccess());

    toast.success("Liqa atualizado com sucesso!");

    history.push("/specifications/pilar3/resources/liqa");
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateLiqaFailure());
  }
}

export default all([
  takeLatest("@liqa/SAVE_REQUEST", saveLiqa),
  takeLatest("@liqa/UPDATE_REQUEST", updateLiqa)
]);
