export function saveCcr8Request(data) {
  return {
    type: '@ccr8/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCcr8Success() {
  return {
    type: '@ccr8/SAVE_SUCCESS',
  }
}

export function saveCcr8Failure() {
  return {
    type: '@ccr8/SAVE_FAILURE',
  }
}

export function updateCcr8Request(data) {
  return {
    type: '@ccr8/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCcr8Success() {
  return {
    type: '@ccr8/UPDATE_SUCCESS',
  }
}

export function updateCcr8Failure() {
  return {
    type: '@ccr8/UPDATE_FAILURE',
  }
}