import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveBaseDateFailure, saveBaseDateSuccess, updateBaseDateFailure, updateBaseDateSuccess } from './actions';

export function* saveBaseDate({ payload }) {
  try {
    const documentId = payload.data.documentId;

    const response = yield call(api.post, `api/document/${documentId}/base-date`, payload.data, { withCredentials: true });

    yield put(saveBaseDateSuccess());

    toast.success('Data de referência criada com sucesso!');

    const id = response.headers.location;

    history.push(`/specifications/demonstrativo/resources/document/${documentId}/base-date/${id}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveBaseDateFailure());
  }
}

export function* updateBaseDate({ payload }) {
  try {
    const { id, documentId } = payload.data;

    yield call(api.post, `api/base-date/${id}`, payload.data, { withCredentials: true });

    yield put(updateBaseDateSuccess());

    toast.success('Data de referência atualizada com sucesso!');

    history.push(`/specifications/demonstrativo/resources/document/${documentId}/base-date/${id}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateBaseDateFailure());
  }
}

export default all([
  takeLatest('@baseDate/SAVE_REQUEST', saveBaseDate),
  takeLatest('@baseDate/UPDATE_REQUEST', updateBaseDate),
]);
