import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const App = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Sidebar = styled.div`
    color: rgb(149, 134, 165);
    line-height: 1;
    width: 220px;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    z-index: 1010;
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgb(74, 62, 86), rgb(48, 40, 57));
    padding: 12px 0px 2px;
`;

export const SidebarSectionGroup = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 0px;
    min-width: 0px;
    flex: 1 1 0%;
`

export const SidebarSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px 0px;
    padding: 0px 19px;
`

export const SidebarDropdownRoot = styled.div`
    position: relative;
`

export const SidebarDropdownActor = styled.button`
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    width: 100%;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    padding: 0px;
    background: none;

    :hover {
        color: rgb(189, 180, 199);
    }
`

export const SidebarAvatar = styled.span`
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 12px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 0px;
    border-radius: 6px;
`

export const OrgAndUserWrapper = styled.div`
    text-align: left;
    overflow: hidden;
`

export const TextOverflowOrg = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    line-height: 1.2;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-shadow: rgba(255, 255, 255, 0) 0px 0px 6px;
    overflow: hidden;
    transition: text-shadow 0.15s linear 0s;
`

export const TextOverflowUser = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    overflow: hidden;
    transition: color 0.15s linear 0s;
`

export const SidebarDropdownMenu = styled.div`
    position: absolute;
    color: rgb(48, 40, 57);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.3) 0px 4px 20px 0px;
    width: 250px;
    top: 42px;
    min-width: 180px;
    z-index: 1011;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 5px 0px;
`

export const OrgSummary = styled.div`
    display: flex;
    padding: 10px 15px;
    overflow: hidden;
`

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    padding-left: 10px;
    overflow: hidden;
`

export const SummaryOrgName = styled.div`
    color: rgb(48, 40, 57);
    font-size: 16px;
    line-height: 1.1;
    font-weight: bold;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
`

export const MenuItemLink = styled(Link)`
    color: rgb(48, 40, 57);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 32px;
    position: relative;
    padding: 0px 15px;
    transition: all 0.1s linear 0s;
    overflow: hidden;

    :hover {
        color: rgb(48, 40, 57);
        background: rgb(250, 249, 251);
        outline: none;
    }

    span {
        flex: 1 1 0%;
        overflow: hidden;
    }
`

export const Divider = styled.div`
    height: 0px;
    border-bottom: 1px solid rgb(226, 219, 232);
    margin: 5px 0px;
`

export const UserSummary = styled.div`
    color: rgb(48, 40, 57);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 32px;
    position: relative;
    transition: all 0.1s linear 0s;
    overflow: hidden;
    padding: 10px 15px;

    :hover {
        color: rgb(48, 40, 57);
        background: rgb(250, 249, 251);
        outline: none;
    }
`

export const StyledUserBadge = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
`

export const StyledNameAndEmail = styled.div`
    flex-shrink: 1;
    min-width: 0px;
    line-height: 1;
    margin-left: 8px;

    span {
        font-weight: bold;
        line-height: 1.15em;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
    }

    div {
        font-size: 0.875em;
        margin-top: 2px;
        color: rgb(149, 133, 163);
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow-x: visible;
    }
`

export const SidebarPrimaryItems = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1 1 0%;
`

export const SidebarItem = styled(Link)`
    display: flex;
    color: inherit;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    line-height: 32px;
    height: 34px;
    flex-shrink: 0;
    transition: color 0.15s linear 0s;

    :hover {
        color: rgb(189, 180, 199);
    }

    :focus {
        color: rgb(189, 180, 199);
    }
`

export const SidebarItemWrapper = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
`

export const SidebarItemIcon = styled.span`
    content: "";
    display: inline-flex;
    width: 32px;
    height: 22px;
    font-size: 20px;
    -webkit-box-align: center;
    align-items: center;

    svg {
        display: block;
        margin: 0px auto;
    }
`

export const SidebarItemLabel = styled.span`
    margin-left: 12px;
    white-space: nowrap;
    opacity: 1;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex: 1 1 0%;
`

export const TextOverflow = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

export const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 60px;
    box-shadow: rgba(37, 11, 54, 0.04) 0px 2px 0px;
    z-index: 1009;
    font-size: 18px;
    border-bottom: 1px solid rgb(209, 202, 216);
    background: rgb(255, 255, 255);
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: rgb(48, 40, 57);
    margin-bottom: -20px;
    line-height: 1;
    flex: 1 1 0%;
`;

export const WrapperColumn = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0px;
    flex: 1 1 0%;
`;

export const MaxWidthContainer = styled.div`
    display: flex;
    max-width: ${props => props.maxWidth};
    min-width: 600px;
    flex: 1 1 0%;
    flex-direction: ${props => props.flexDirection};
    margin: ${props => props.margin};
`;