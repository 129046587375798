import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { updateSetFailure, updateSetSuccess } from './actions';

export function* updateSet({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/conjunto/${id}`, payload.data, { withCredentials: true });

    yield put(updateSetSuccess());

    toast.success('Conjunto atualizado com sucesso!');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateSetFailure());
  }
}

export default all([
  takeLatest('@set/UPDATE_REQUEST', updateSet)
]);