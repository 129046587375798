export function saveRem3Request(data) {
  return {
    type: '@rem3/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveRem3Success() {
  return {
    type: '@rem3/SAVE_SUCCESS',
  }
}

export function saveRem3Failure() {
  return {
    type: '@rem3/SAVE_FAILURE',
  }
}

export function updateRem3Request(data) {
  return {
    type: '@rem3/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateRem3Success() {
  return {
    type: '@rem3/UPDATE_SUCCESS',
  }
}

export function updateRem3Failure() {
  return {
    type: '@rem3/UPDATE_FAILURE',
  }
}