export function saveCrbRequest(data) {
  return {
    type: '@crb/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCrbSuccess() {
  return {
    type: '@crb/SAVE_SUCCESS',
  }
}

export function saveCrbFailure() {
  return {
    type: '@crb/SAVE_FAILURE',
  }
}

export function updateCrbRequest(data) {
  return {
    type: '@crb/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCrbSuccess() {
  return {
    type: '@crb/UPDATE_SUCCESS',
  }
}

export function updateCrbFailure() {
  return {
    type: '@crb/UPDATE_FAILURE',
  }
}