import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveGsib1Failure, saveGsib1Success, updateGsib1Failure, updateGsib1Success } from './actions';

export function* saveGsib1({ payload }) {
  try {
    yield call(api.post, 'api/gsib1', payload.data, { withCredentials: true });

    yield put(saveGsib1Success());

    toast.success('Gsib1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/gsib1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveGsib1Failure());
  }
}

export function* updateGsib1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/gsib1/${id}`, payload.data, { withCredentials: true });

    yield put(updateGsib1Success());

    toast.success('Gsib1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/gsib1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateGsib1Failure());
  }
}

export default all([
  takeLatest('@gsib1/SAVE_REQUEST', saveGsib1),
  takeLatest('@gsib1/UPDATE_REQUEST', updateGsib1)
]);