export function saveCraRequest(data) {
  return {
    type: '@cra/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCraSuccess() {
  return {
    type: '@cra/SAVE_SUCCESS',
  }
}

export function saveCraFailure() {
  return {
    type: '@cra/SAVE_FAILURE',
  }
}

export function updateCraRequest(data) {
  return {
    type: '@cra/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCraSuccess() {
  return {
    type: '@cra/UPDATE_SUCCESS',
  }
}

export function updateCraFailure() {
  return {
    type: '@cra/UPDATE_FAILURE',
  }
}