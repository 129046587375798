export function saveLi1Request(data) {
  return {
    type: '@li1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveLi1Success() {
  return {
    type: '@li1/SAVE_SUCCESS',
  }
}

export function saveLi1Failure() {
  return {
    type: '@li1/SAVE_FAILURE',
  }
}

export function updateLi1Request(data) {
  return {
    type: '@li1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateLi1Success() {
  return {
    type: '@li1/UPDATE_SUCCESS',
  }
}

export function updateLi1Failure() {
  return {
    type: '@li1/UPDATE_FAILURE',
  }
}