import { combineReducers } from "redux";

import auth from "./auth/reducer";
import user from "./user/reducer";
import branch from "./branch/reducer";
import km1 from "./km1/reducer";
import ova from "./ova/reducer";
import ov1 from "./ov1/reducer";
import lia from "./lia/reducer";
import li1 from "./li1/reducer";
import li2 from "./li2/reducer";
import pv1 from "./pv1/reducer";
import cca from "./cca/reducer";
import cc1 from "./cc1/reducer";
import cc2 from "./cc2/reducer";
import gsib1 from "./gsib1/reducer";
import ccyb1 from "./ccyb1/reducer";
import lr1 from "./lr1/reducer";
import lr2 from "./lr2/reducer";
import liqa from "./liqa/reducer";
import liq1 from "./liq1/reducer";
import liq2 from "./liq2/reducer";
import cra from "./cra/reducer";
import cr1 from "./cr1/reducer";
import cr2 from "./cr2/reducer";
import crb from "./crb/reducer";
import crc from "./crc/reducer";
import cr3 from "./cr3/reducer";
import cr4 from "./cr4/reducer";
import cr5 from "./cr5/reducer";
import ccra from "./ccra/reducer";
import ccr1 from "./ccr1/reducer";
import ccr3 from "./ccr3/reducer";
import ccr5 from "./ccr5/reducer";
import ccr6 from "./ccr6/reducer";
import ccr8 from "./ccr8/reducer";
import seca from "./seca/reducer";
import sec1 from "./sec1/reducer";
import sec2 from "./sec2/reducer";
import sec3 from "./sec3/reducer";
import sec4 from "./sec4/reducer";
import mra from "./mra/reducer";
import mr1 from "./mr1/reducer";
import mrb from "./mrb/reducer";
import mr2 from "./mr2/reducer";
import mr3 from "./mr3/reducer";
import mr4 from "./mr4/reducer";
import irrbba from "./irrbba/reducer";
import irrbb1 from "./irrbb1/reducer";
import rema from "./rema/reducer";
import rem1 from "./rem1/reducer";
import rem2 from "./rem2/reducer";
import rem3 from "./rem3/reducer";
import taxa from "./taxa/reducer";
import organization from "./organization/reducer";
import email from "./email/reducer";
import set from "./set/reducer";
import document from "./document/reducer";
import baseDate from "./baseDate/reducer";
import balanceSheet from "./balanceSheet/reducer";
import cashFlowStatement from "./cashFlowStatement/reducer";
import equityChangeStatement from "./equityChangeStatement/reducer";
import incomeStatement from "./incomeStatement/reducer";
import incomeStatementEmbracing from "./incomeStatementEmbracing/reducer";
import role from "./role/reducer";
import agenteSaque from "./agenteSaque/reducer";

export default combineReducers({
  auth,
  user,
  branch,
  organization,
  set,
  km1,
  ova,
  ov1,
  lia,
  li1,
  li2,
  pv1,
  cca,
  cc1,
  cc2,
  gsib1,
  ccyb1,
  lr1,
  lr2,
  liqa,
  liq1,
  liq2,
  cra,
  cr1,
  cr2,
  crb,
  crc,
  cr3,
  cr4,
  cr5,
  ccra,
  ccr1,
  ccr3,
  ccr5,
  ccr6,
  ccr8,
  seca,
  sec1,
  sec2,
  sec3,
  sec4,
  mra,
  mr1,
  mrb,
  mr2,
  mr3,
  mr4,
  irrbba,
  irrbb1,
  rema,
  rem1,
  rem2,
  rem3,
  taxa,
  email,
  document,
  baseDate,
  balanceSheet,
  cashFlowStatement,
  equityChangeStatement,
  incomeStatement,
  incomeStatementEmbracing,
  role,
  agenteSaque,
});
