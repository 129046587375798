import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Box, AuthSidebar, Content } from './styles';

import logo from '~/assets/logo-origam-negativo.png';

export default function AuthLayout({ children }) {
    return (
        <Wrapper>
            <Box>
                <AuthSidebar><img src={logo} alt="DASFN" /></AuthSidebar>
                <Content>{children}</Content>
            </Box>
        </Wrapper>
    )
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired,
}