import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCr5Failure, saveCr5Success, updateCr5Failure, updateCr5Success } from './actions';

export function* saveCr5({ payload }) {
  try {
    yield call(api.post, 'api/cr5', payload.data, { withCredentials: true });

    yield put(saveCr5Success());

    toast.success('CR5 criado com sucesso!')

    history.push('/specifications/pilar3/resources/cr5');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCr5Failure());
  }
}

export function* updateCr5({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/cr5/${id}`, payload.data, { withCredentials: true });

    yield put(updateCr5Success());

    toast.success('CR5 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/cr5');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCr5Failure());
  }
}

export default all([
  takeLatest('@cr5/SAVE_REQUEST', saveCr5),
  takeLatest('@cr5/UPDATE_REQUEST', updateCr5)
]);