import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import history from "~/services/history";
import api from "~/services/api";

import {
  saveLiq1Failure,
  saveLiq1Success,
  updateLiq1Failure,
  updateLiq1Success
} from "./actions";

export function* saveLiq1({ payload }) {
  try {
    yield call(api.post, "api/liq1", payload.data, { withCredentials: true });

    yield put(saveLiq1Success());

    toast.success("Liq1 criado com sucesso!");

    history.push("/specifications/pilar3/resources/liq1");
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveLiq1Failure());
  }
}

export function* updateLiq1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/liq1/${id}`, payload.data, { withCredentials: true });

    yield put(updateLiq1Success());

    toast.success("Liq1 atualizado com sucesso!");

    history.push("/specifications/pilar3/resources/liq1");
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateLiq1Failure());
  }
}

export default all([
  takeLatest("@liq1/SAVE_REQUEST", saveLiq1),
  takeLatest("@liq1/UPDATE_REQUEST", updateLiq1)
]);
