import { all } from "redux-saga/effects";

import auth from "./auth/sagas";
import user from "./user/sagas";
import branch from "./branch/sagas";
import km1 from "./km1/sagas";
import ova from "./ova/sagas";
import ov1 from "./ov1/sagas";
import lia from "./lia/sagas";
import li1 from "./li1/sagas";
import li2 from "./li2/sagas";
import pv1 from "./pv1/sagas";
import cca from "./cca/sagas";
import cc1 from "./cc1/sagas";
import cc2 from "./cc2/sagas";
import gsib1 from "./gsib1/sagas";
import ccyb1 from "./ccyb1/sagas";
import lr1 from "./lr1/sagas";
import lr2 from "./lr2/sagas";
import liqa from "./liqa/sagas";
import liq1 from "./liq1/sagas";
import liq2 from "./liq2/sagas";
import cra from "./cra/sagas";
import cr1 from "./cr1/sagas";
import cr2 from "./cr2/sagas";
import crb from "./crb/sagas";
import crc from "./crc/sagas";
import cr3 from "./cr3/sagas";
import cr4 from "./cr4/sagas";
import cr5 from "./cr5/sagas";
import ccra from "./ccra/sagas";
import ccr1 from "./ccr1/sagas";
import ccr3 from "./ccr3/sagas";
import ccr5 from "./ccr5/sagas";
import ccr6 from "./ccr6/sagas";
import ccr8 from "./ccr8/sagas";
import seca from "./seca/sagas";
import sec1 from "./sec1/sagas";
import sec2 from "./sec2/sagas";
import sec3 from "./sec3/sagas";
import sec4 from "./sec4/sagas";
import mra from "./mra/sagas";
import mr1 from "./mr1/sagas";
import mrb from "./mrb/sagas";
import mr2 from "./mr2/sagas";
import mr3 from "./mr3/sagas";
import mr4 from "./mr4/sagas";
import irrbba from "./irrbba/sagas";
import irrbb1 from "./irrbb1/sagas";
import rema from "./rema/sagas";
import rem1 from "./rem1/sagas";
import rem2 from "./rem2/sagas";
import rem3 from "./rem3/sagas";
import taxa from "./taxa/sagas";
import organization from "./organization/sagas";
import email from "./email/sagas";
import set from "./set/sagas";
import document from "./document/sagas";
import baseDate from "./baseDate/sagas";
import balanceSheet from "./balanceSheet/sagas";
import cashFlowStatement from "./cashFlowStatement/sagas";
import equityChangeStatement from "./equityChangeStatement/sagas";
import incomeStatement from "./incomeStatement/sagas";
import incomeStatementEmbracing from "./incomeStatementEmbracing/sagas";
import role from "./role/sagas";
import agenteSaque from "./agenteSaque/sagas";

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    branch,
    organization,
    set,
    km1,
    ova,
    ov1,
    lia,
    li1,
    li2,
    pv1,
    cca,
    cc1,
    cc2,
    gsib1,
    ccyb1,
    lr1,
    lr2,
    liqa,
    liq1,
    liq2,
    cra,
    cr1,
    cr2,
    crb,
    crc,
    cr3,
    cr4,
    cr5,
    ccra,
    ccr1,
    ccr3,
    ccr5,
    ccr6,
    ccr8,
    seca,
    sec1,
    sec2,
    sec3,
    sec4,
    mra,
    mr1,
    mrb,
    mr2,
    mr3,
    mr4,
    irrbba,
    irrbb1,
    rema,
    rem1,
    rem2,
    rem3,
    taxa,
    email,
    document,
    baseDate,
    balanceSheet,
    cashFlowStatement,
    equityChangeStatement,
    incomeStatement,
    incomeStatementEmbracing,
    role,
    agenteSaque,
  ]);
}
