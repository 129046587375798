import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveSec1Failure, saveSec1Success, updateSec1Failure, updateSec1Success } from './actions';

export function* saveSec1({ payload }) {
  try {
    yield call(api.post, 'api/sec1', payload.data, { withCredentials: true });

    yield put(saveSec1Success());

    toast.success('SEC1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/sec1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveSec1Failure());
  }
}

export function* updateSec1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/sec1/${id}`, payload.data, { withCredentials: true });

    yield put(updateSec1Success());

    toast.success('SEC1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/sec1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateSec1Failure());
  }
}

export default all([
  takeLatest('@sec1/SAVE_REQUEST', saveSec1),
  takeLatest('@sec1/UPDATE_REQUEST', updateSec1)
]);