import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCrcFailure, saveCrcSuccess, updateCrcFailure, updateCrcSuccess } from './actions';

export function* saveCrc({ payload }) {
  try {
    yield call(api.post, 'api/crc', payload.data, { withCredentials: true });

    yield put(saveCrcSuccess());

    toast.success('CRC criado com sucesso!')

    history.push('/specifications/pilar3/resources/crc');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCrcFailure());
  }
}

export function* updateCrc({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/crc/${id}`, payload.data, { withCredentials: true });

    yield put(updateCrcSuccess());

    toast.success('CRC atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/crc');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCrcFailure());
  }
}

export default all([
  takeLatest('@crc/SAVE_REQUEST', saveCrc),
  takeLatest('@crc/UPDATE_REQUEST', updateCrc)
]);