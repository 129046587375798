import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveMr3Failure, saveMr3Success, updateMr3Failure, updateMr3Success } from './actions';

export function* saveMr3({ payload }) {
  try {
    yield call(api.post, 'api/mr3', payload.data, { withCredentials: true });

    yield put(saveMr3Success());

    toast.success('MR3 criado com sucesso!')

    history.push('/specifications/pilar3/resources/mr3');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveMr3Failure());
  }
}

export function* updateMr3({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/mr3/${id}`, payload.data, { withCredentials: true });

    yield put(updateMr3Success());

    toast.success('MR3 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/mr3');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateMr3Failure());
  }
}

export default all([
  takeLatest('@mr3/SAVE_REQUEST', saveMr3),
  takeLatest('@mr3/UPDATE_REQUEST', updateMr3)
]);