export function saveLiqaRequest(data) {
  return {
    type: '@liqa/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveLiqaSuccess() {
  return {
    type: '@liqa/SAVE_SUCCESS',
  }
}

export function saveLiqaFailure() {
  return {
    type: '@liqa/SAVE_FAILURE',
  }
}

export function updateLiqaRequest(data) {
  return {
    type: '@liqa/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateLiqaSuccess() {
  return {
    type: '@liqa/UPDATE_SUCCESS',
  }
}

export function updateLiqaFailure() {
  return {
    type: '@liqa/UPDATE_FAILURE',
  }
}