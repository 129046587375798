import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveRem1Failure, saveRem1Success, updateRem1Failure, updateRem1Success } from './actions';

export function* saveRem1({ payload }) {
  try {
    yield call(api.post, 'api/rem1', payload.data, { withCredentials: true });

    yield put(saveRem1Success());

    toast.success('REM1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/rem1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveRem1Failure());
  }
}

export function* updateRem1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/rem1/${id}`, payload.data, { withCredentials: true });

    yield put(updateRem1Success());

    toast.success('REM1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/rem1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateRem1Failure());
  }
}

export default all([
  takeLatest('@rem1/SAVE_REQUEST', saveRem1),
  takeLatest('@rem1/UPDATE_REQUEST', updateRem1)
]);