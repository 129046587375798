export function saveOvaRequest(data) {
  return {
    type: '@ova/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveOvaSuccess() {
  return {
    type: '@ova/SAVE_SUCCESS',
  }
}

export function saveOvaFailure() {
  return {
    type: '@ova/SAVE_FAILURE',
  }
}

export function updateOvaRequest(data) {
  return {
    type: '@ova/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateOvaSuccess() {
  return {
    type: '@ova/UPDATE_SUCCESS',
  }
}

export function updateOvaFailure() {
  return {
    type: '@ova/UPDATE_FAILURE',
  }
}