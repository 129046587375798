import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { updateUserSuccess, updateUserFailure, updateUserPasswordSuccess, updateUserPasswordFailure, updateUserRoleSuccess, updateUserRoleFailure } from './actions';

export function* updateUser({ payload }) {
    try {
        const { id } = payload.data;
        const response = yield call(api.post, `api/user/${id}`, payload.data, { withCredentials: true });

        toast.success('Usuário atualizado com sucesso!')

        yield put(updateUserSuccess(response.data));
    } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else if (error?.message) {
          toast.error(error.message);
        } else {
          toast.error('Ocorreu um erro inesperado. Tente novamente.');
        }
        yield put(updateUserFailure());
    }
}

export function* updateUserPassword({ payload }) {
    try {
        const { id } = payload.data;
        yield call(api.post, `api/user/${id}/password`, payload.data, { withCredentials: true });

        yield put(updateUserPasswordSuccess());

        toast.success('Senha alterada com sucesso!')        

        history.push(`/settings/account/details`);
    } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else if (error?.message) {
          toast.error(error.message);
        } else {
          toast.error('Ocorreu um erro inesperado. Tente novamente.');
        }
        yield put(updateUserPasswordFailure());
    }
}

export function* updateUserRole({ payload }) {
  try {
      const { id } = payload.data;
      yield call(api.post, `api/user/${id}/role`, payload.data, { withCredentials: true });      

      yield put(updateUserRoleSuccess());

      toast.success('Perfil de acesso alterado com sucesso.')

      history.push(`/settings/organization/users/${id}`);
  } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else if (error?.message) {
        toast.error(error.message);
      } else {
        toast.error('Ocorreu um erro inesperado. Tente novamente.');
      }
      yield put(updateUserRoleFailure());
  }
}

export default all([
    takeLatest('@user/UPDATE_REQUEST', updateUser),
    takeLatest('@user/UPDATE_PASSWORD_REQUEST', updateUserPassword),
    takeLatest('@user/UPDATE_ROLE_REQUEST', updateUserRole)
]);