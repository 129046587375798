import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveMr4Failure, saveMr4Success, updateMr4Failure, updateMr4Success } from './actions';

export function* saveMr4({ payload }) {
  try {
    yield call(api.post, 'api/mr4', payload.data, { withCredentials: true });

    yield put(saveMr4Success());

    toast.success('MR4 criado com sucesso!')

    history.push('/specifications/pilar3/resources/mr4');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveMr4Failure());
  }
}

export function* updateMr4({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/mr4/${id}`, payload.data, { withCredentials: true });

    yield put(updateMr4Success());

    toast.success('MR4 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/mr4');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateMr4Failure());
  }
}

export default all([
  takeLatest('@mr4/SAVE_REQUEST', saveMr4),
  takeLatest('@mr4/UPDATE_REQUEST', updateMr4)
]);