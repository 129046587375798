import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';
import PrivateRoute from './PrivateRoute';
import LazyImport from '../components/LazyImport';

export default function Routes() {
    const SignIn = LazyImport(() => import('../pages/SignIn'/* webpackChunkName: 'signIn' */));
    const SignUp = LazyImport(() => import('../pages/SignUp'/* webpackChunkName: 'signUp' */));
    const ForgotPassword = LazyImport(() => import('../pages/ForgotPassword'/* webpackChunkName: 'forgotPassword' */));
    const ResetPassword = LazyImport(() => import('../pages/ResetPassword'/* webpackChunkName: 'resetPassword' */));
    const ChangePasswordChallenge = LazyImport(() => import('../pages/ChangePasswordChallenge'/* webpackChunkName: 'changePasswordChallenge' */));

    const Specifications = LazyImport(() => import('../pages/Specifications'/* webpackChunkName: 'specifications' */));
    const Profile = LazyImport(() => import('../pages/Profile'/* webpackChunkName: 'profile' */));
    const Pilar3 = LazyImport(() => import('../pages/Pilar3'/* webpackChunkName: 'pilar3' */));
    const Pilar3View = LazyImport(() => import('../pages/Pilar3View'/* webpackChunkName: 'pilar3View' */));
    const Cartoes = LazyImport(() => import('../pages/Cartoes'/* webpackChunkName: 'cartoes' */));

    const Km1List = LazyImport(() => import('../pages/Km1List'/* webpackChunkName: 'km1List' */));
    const Km1ListView = LazyImport(() => import('../pages/Km1ListView'/* webpackChunkName: 'km1ListView' */));
    const Km1New = LazyImport(() => import('../pages/Km1New'/* webpackChunkName: 'km1New' */));
    const Km1Edit = LazyImport(() => import('../pages/Km1Edit'/* webpackChunkName: 'km1Edit' */));
    const Km1View = LazyImport(() => import('../pages/Km1View'/* webpackChunkName: 'km1View' */));

    const OvaList = LazyImport(() => import('../pages/OvaList'/* webpackChunkName: 'ovaList' */));
    const OvaListView = LazyImport(() => import('../pages/OvaListView'/* webpackChunkName: 'ovaListView' */));
    const OvaNew = LazyImport(() => import('../pages/OvaNew'/* webpackChunkName: 'ovaNew' */));
    const OvaEdit = LazyImport(() => import('../pages/OvaEdit'/* webpackChunkName: 'ovaEdit' */));
    const OvaView = LazyImport(() => import('../pages/OvaView'/* webpackChunkName: 'ovaView' */));

    const Ov1List = LazyImport(() => import('../pages/Ov1List'/* webpackChunkName: 'ov1List' */));
    const Ov1ListView = LazyImport(() => import('../pages/Ov1ListView'/* webpackChunkName: 'ov1ListView' */));
    const Ov1New = LazyImport(() => import('../pages/Ov1New'/* webpackChunkName: 'ov1New' */));
    const Ov1Edit = LazyImport(() => import('~/pages/Ov1Edit'/* webpackChunkName: 'ov1Edit' */));
    const Ov1View = LazyImport(() => import('~/pages/Ov1View'/* webpackChunkName: 'ov1View' */));

    const LiaList = LazyImport(() => import('../pages/LiaList'/* webpackChunkName: 'liaList' */));
    const LiaListView = LazyImport(() => import('../pages/LiaListView'/* webpackChunkName: 'liaListView' */));
    const LiaNew = LazyImport(() => import('../pages/LiaNew'/* webpackChunkName: 'liaNew' */));
    const LiaEdit = LazyImport(() => import('~/pages/LiaEdit'/* webpackChunkName: 'liaEdit' */));
    const LiaView = LazyImport(() => import('~/pages/LiaView'/* webpackChunkName: 'liaView' */));

    const Li1List = LazyImport(() => import('../pages/Li1List'/* webpackChunkName: 'li1List' */));
    const Li1ListView = LazyImport(() => import('../pages/Li1ListView'/* webpackChunkName: 'li1ListView' */));
    const Li1New = LazyImport(() => import('../pages/Li1New'/* webpackChunkName: 'li1New' */));
    const Li1Edit = LazyImport(() => import('~/pages/Li1Edit'/* webpackChunkName: 'li1Edit' */));
    const Li1View = LazyImport(() => import('~/pages/Li1View'/* webpackChunkName: 'li1View' */));

    const Li2List = LazyImport(() => import('../pages/Li2List'/* webpackChunkName: 'li2List' */));
    const Li2ListView = LazyImport(() => import('../pages/Li2ListView'/* webpackChunkName: 'li2ListView' */));
    const Li2New = LazyImport(() => import('../pages/Li2New'/* webpackChunkName: 'li2New' */));
    const Li2Edit = LazyImport(() => import('../pages/Li2Edit'/* webpackChunkName: 'li2Edit' */));
    const Li2View = LazyImport(() => import('../pages/Li2View'/* webpackChunkName: 'li2View' */));

    const Pv1List = LazyImport(() => import('../pages/Pv1List'/* webpackChunkName: 'pv1List' */));
    const Pv1ListView = LazyImport(() => import('../pages/Pv1ListView'/* webpackChunkName: 'pv1ListView' */));
    const Pv1New = LazyImport(() => import('../pages/Pv1New'/* webpackChunkName: 'pv1New' */));
    const Pv1Edit = LazyImport(() => import('../pages/Pv1Edit'/* webpackChunkName: 'pv1Edit' */));
    const Pv1View = LazyImport(() => import('../pages/Pv1View'/* webpackChunkName: 'pv1View' */));

    const CcaList = LazyImport(() => import('../pages/CcaList'/* webpackChunkName: 'ccaList' */));
    const CcaListView = LazyImport(() => import('../pages/CcaListView'/* webpackChunkName: 'ccaListView' */));
    const CcaNew = LazyImport(() => import('../pages/CcaNew'/* webpackChunkName: 'ccaNew' */));
    const CcaEdit = LazyImport(() => import('../pages/CcaEdit'/* webpackChunkName: 'ccaEdit' */));
    const CcaView = LazyImport(() => import('../pages/CcaView'/* webpackChunkName: 'ccaView' */));

    const Cc1List = LazyImport(() => import('../pages/Cc1List'/* webpackChunkName: 'cc1List' */));
    const Cc1ListView = LazyImport(() => import('../pages/Cc1ListView'/* webpackChunkName: 'cc1ListView' */));
    const Cc1New = LazyImport(() => import('../pages/Cc1New'/* webpackChunkName: 'cc1New' */));
    const Cc1Edit = LazyImport(() => import('../pages/Cc1Edit'/* webpackChunkName: 'cc1Edit' */));
    const Cc1View = LazyImport(() => import('../pages/Cc1View'/* webpackChunkName: 'cc1View' */));

    const Cc2List = LazyImport(() => import('../pages/Cc2List'/* webpackChunkName: 'cc2List' */));
    const Cc2ListView = LazyImport(() => import('../pages/Cc2ListView'/* webpackChunkName: 'cc2ListView' */));
    const Cc2New = LazyImport(() => import('../pages/Cc2New'/* webpackChunkName: 'cc2New' */));
    const Cc2Edit = LazyImport(() => import('../pages/Cc2Edit'/* webpackChunkName: 'cc2Edit' */));
    const Cc2View = LazyImport(() => import('../pages/Cc2View'/* webpackChunkName: 'cc2View' */));

    const Gsib1List = LazyImport(() => import('../pages/Gsib1List'/* webpackChunkName: 'gsib1List' */));
    const Gsib1ListView = LazyImport(() => import('../pages/Gsib1ListView'/* webpackChunkName: 'gsib1ListView' */));
    const Gsib1New = LazyImport(() => import('../pages/Gsib1New'/* webpackChunkName: 'gsib1New' */));
    const Gsib1Edit = LazyImport(() => import('../pages/Gsib1Edit'/* webpackChunkName: 'gsib1Edit' */));
    const Gsib1View = LazyImport(() => import('../pages/Gsib1View'/* webpackChunkName: 'gsib1View' */));

    const Ccyb1List = LazyImport(() => import('../pages/Ccyb1List'/* webpackChunkName: 'ccyb1List' */));
    const Ccyb1ListView = LazyImport(() => import('../pages/Ccyb1ListView'/* webpackChunkName: 'ccyb1ListView' */));
    const Ccyb1New = LazyImport(() => import('../pages/Ccyb1New'/* webpackChunkName: 'ccyb1New' */));
    const Ccyb1Edit = LazyImport(() => import('../pages/Ccyb1Edit'/* webpackChunkName: 'ccyb1Edit' */));
    const Ccyb1View = LazyImport(() => import('../pages/Ccyb1View'/* webpackChunkName: 'ccyb1View' */));

    const Lr1Edit = LazyImport(() => import('../pages/Lr1Edit'/* webpackChunkName: 'lr1Edit' */));
    const Lr1View = LazyImport(() => import('../pages/Lr1View'/* webpackChunkName: 'lr1View' */));
    const Lr1List = LazyImport(() => import('../pages/Lr1List'/* webpackChunkName: 'lr1List' */));
    const Lr1ListView = LazyImport(() => import('../pages/Lr1ListView'/* webpackChunkName: 'lr1ListView' */));
    const Lr1New = LazyImport(() => import('../pages/Lr1New'/* webpackChunkName: 'lr1New' */));

    const Lr2Edit = LazyImport(() => import('../pages/Lr2Edit'/* webpackChunkName: 'lr2Edit' */));
    const Lr2View = LazyImport(() => import('../pages/Lr2View'/* webpackChunkName: 'lr2View' */));
    const Lr2List = LazyImport(() => import('../pages/Lr2List'/* webpackChunkName: 'lr2List' */));
    const Lr2ListView = LazyImport(() => import('../pages/Lr2ListView'/* webpackChunkName: 'lr2ListView' */));
    const Lr2New = LazyImport(() => import('../pages/Lr2New'/* webpackChunkName: 'lr2New' */));

    const LiqaList = LazyImport(() => import('../pages/LiqaList'/* webpackChunkName: 'liqaList' */));
    const LiqaListView = LazyImport(() => import('../pages/LiqaListView'/* webpackChunkName: 'liqaListView' */));
    const LiqaNew = LazyImport(() => import('../pages/LiqaNew'/* webpackChunkName: 'liqaNew' */));
    const LiqaEdit = LazyImport(() => import('../pages/LiqaEdit'/* webpackChunkName: 'liqaEdit' */));
    const LiqaView = LazyImport(() => import('../pages/LiqaView'/* webpackChunkName: 'liqaView' */));

    const Liq1List = LazyImport(() => import('../pages/Liq1List'/* webpackChunkName: 'liq1List' */));
    const Liq1ListView = LazyImport(() => import('../pages/Liq1ListView'/* webpackChunkName: 'liq1ListView' */));
    const Liq1New = LazyImport(() => import('../pages/Liq1New'/* webpackChunkName: 'liq1New' */));
    const Liq1Edit = LazyImport(() => import('../pages/Liq1Edit'/* webpackChunkName: 'liq1Edit' */));
    const Liq1View = LazyImport(() => import('../pages/Liq1View'/* webpackChunkName: 'liq1View' */));

    const Liq2List = LazyImport(() => import('../pages/Liq2List'/* webpackChunkName: 'liq2List' */));
    const Liq2ListView = LazyImport(() => import('../pages/Liq2ListView'/* webpackChunkName: 'liq2ListView' */));
    const Liq2New = LazyImport(() => import('../pages/Liq2New'/* webpackChunkName: 'liq2New' */));
    const Liq2Edit = LazyImport(() => import('../pages/Liq2Edit'/* webpackChunkName: 'liq2Edit' */));
    const Liq2View = LazyImport(() => import('../pages/Liq2View'/* webpackChunkName: 'liq2View' */));

    const CraList = LazyImport(() => import('../pages/CraList'/* webpackChunkName: 'craList' */));
    const CraListView = LazyImport(() => import('../pages/CraListView'/* webpackChunkName: 'craListView' */));
    const CraNew = LazyImport(() => import('../pages/CraNew'/* webpackChunkName: 'craNew' */));
    const CraEdit = LazyImport(() => import('../pages/CraEdit'/* webpackChunkName: 'craEdit' */));
    const CraView = LazyImport(() => import('../pages/CraView'/* webpackChunkName: 'craView' */));

    const Cr1List = LazyImport(() => import('../pages/Cr1List'/* webpackChunkName: 'cr1List' */));
    const Cr1ListView = LazyImport(() => import('../pages/Cr1ListView'/* webpackChunkName: 'cr1ListView' */));
    const Cr1New = LazyImport(() => import('../pages/Cr1New'/* webpackChunkName: 'cr1New' */));
    const Cr1Edit = LazyImport(() => import('../pages/Cr1Edit'/* webpackChunkName: 'cr1Edit' */));
    const Cr1View = LazyImport(() => import('../pages/Cr1View'/* webpackChunkName: 'cr1View' */));

    const Cr2List = LazyImport(() => import('../pages/Cr2List'/* webpackChunkName: 'cr2List' */));
    const Cr2ListView = LazyImport(() => import('../pages/Cr2ListView'/* webpackChunkName: 'cr2ListView' */));
    const Cr2New = LazyImport(() => import('../pages/Cr2New'/* webpackChunkName: 'cr2New' */));
    const Cr2Edit = LazyImport(() => import('../pages/Cr2Edit'/* webpackChunkName: 'cr2Edit' */));
    const Cr2View = LazyImport(() => import('../pages/Cr2View'/* webpackChunkName: 'cr2View' */));

    const CrbList = LazyImport(() => import('../pages/CrbList'/* webpackChunkName: 'crbList' */));
    const CrbListView = LazyImport(() => import('../pages/CrbListView'/* webpackChunkName: 'crbListView' */));
    const CrbNew = LazyImport(() => import('../pages/CrbNew'/* webpackChunkName: 'crbNew' */));
    const CrbEdit = LazyImport(() => import('../pages/CrbEdit'/* webpackChunkName: 'crbEdit' */));
    const CrbView = LazyImport(() => import('../pages/CrbView'/* webpackChunkName: 'crbView' */));

    const CrcList = LazyImport(() => import('../pages/CrcList'/* webpackChunkName: 'crcList' */));
    const CrcListView = LazyImport(() => import('../pages/CrcListView'/* webpackChunkName: 'crcListView' */));
    const CrcNew = LazyImport(() => import('../pages/CrcNew'/* webpackChunkName: 'crcNew' */));
    const CrcEdit = LazyImport(() => import('../pages/CrcEdit'/* webpackChunkName: 'crcEdit' */));
    const CrcView = LazyImport(() => import('../pages/CrcView'/* webpackChunkName: 'crcView' */));

    const Cr3New = LazyImport(() => import('../pages/Cr3New'/* webpackChunkName: 'cr3New' */));
    const Cr3List = LazyImport(() => import('../pages/Cr3List'/* webpackChunkName: 'cr3List' */));
    const Cr3ListView = LazyImport(() => import('../pages/Cr3ListView'/* webpackChunkName: 'cr3ListView' */));
    const Cr3Edit = LazyImport(() => import('../pages/Cr3Edit'/* webpackChunkName: 'cr3Edit' */));
    const Cr3View = LazyImport(() => import('../pages/Cr3View'/* webpackChunkName: 'cr3View' */));

    const Cr4List = LazyImport(() => import('../pages/Cr4List'/* webpackChunkName: 'cr4List' */));
    const Cr4ListView = LazyImport(() => import('../pages/Cr4ListView'/* webpackChunkName: 'cr4ListView' */));
    const Cr4New = LazyImport(() => import('../pages/Cr4New'/* webpackChunkName: 'cr4New' */));
    const Cr4Edit = LazyImport(() => import('../pages/Cr4Edit'/* webpackChunkName: 'cr4Edit' */));
    const Cr4View = LazyImport(() => import('../pages/Cr4View'/* webpackChunkName: 'cr4View' */));

    const Cr5List = LazyImport(() => import('../pages/Cr5List'/* webpackChunkName: 'cr5List' */));
    const Cr5ListView = LazyImport(() => import('../pages/Cr5ListView'/* webpackChunkName: 'cr5ListView' */));
    const Cr5New = LazyImport(() => import('../pages/Cr5New'/* webpackChunkName: 'cr5New' */));
    const Cr5Edit = LazyImport(() => import('../pages/Cr5Edit'/* webpackChunkName: 'cr5Edit' */));
    const Cr5View = LazyImport(() => import('../pages/Cr5View'/* webpackChunkName: 'cr5View' */));

    const CcraList = LazyImport(() => import('../pages/CcraList'/* webpackChunkName: 'ccraList' */));
    const CcraListView = LazyImport(() => import('../pages/CcraListView'/* webpackChunkName: 'ccraListView' */));
    const CcraNew = LazyImport(() => import('../pages/CcraNew'/* webpackChunkName: 'ccraNew' */));
    const CcraEdit = LazyImport(() => import('../pages/CcraEdit'/* webpackChunkName: 'ccraEdit' */));
    const CcraView = LazyImport(() => import('../pages/CcraView'/* webpackChunkName: 'ccraView' */));

    const Ccr1List = LazyImport(() => import('../pages/Ccr1List'/* webpackChunkName: 'ccr1List' */));
    const Ccr1ListView = LazyImport(() => import('../pages/Ccr1ListView'/* webpackChunkName: 'ccr1ListView' */));
    const Ccr1New = LazyImport(() => import('../pages/Ccr1New'/* webpackChunkName: 'ccr1New' */));
    const Ccr1Edit = LazyImport(() => import('../pages/Ccr1Edit'/* webpackChunkName: 'ccr1Edit' */));
    const Ccr1View = LazyImport(() => import('../pages/Ccr1View'/* webpackChunkName: 'ccr1View' */));

    const Ccr3List = LazyImport(() => import('../pages/Ccr3List'/* webpackChunkName: 'ccr3List' */));
    const Ccr3ListView = LazyImport(() => import('../pages/Ccr3ListView'/* webpackChunkName: 'ccr3ListView' */));
    const Ccr3New = LazyImport(() => import('../pages/Ccr3New'/* webpackChunkName: 'ccr3New' */));
    const Ccr3Edit = LazyImport(() => import('../pages/Ccr3Edit'/* webpackChunkName: 'ccr3Edit' */));
    const Ccr3View = LazyImport(() => import('../pages/Ccr3View'/* webpackChunkName: 'ccr3View' */));

    const Ccr5List = LazyImport(() => import('../pages/Ccr5List'/* webpackChunkName: 'ccr5List' */));
    const Ccr5ListView = LazyImport(() => import('../pages/Ccr5ListView'/* webpackChunkName: 'ccr5ListView' */));
    const Ccr5New = LazyImport(() => import('../pages/Ccr5New'/* webpackChunkName: 'ccr5New' */));
    const Ccr5Edit = LazyImport(() => import('../pages/Ccr5Edit'/* webpackChunkName: 'ccr5Edit' */));
    const Ccr5View = LazyImport(() => import('../pages/Ccr5View'/* webpackChunkName: 'ccr5View' */));

    const Ccr6List = LazyImport(() => import('../pages/Ccr6List'/* webpackChunkName: 'ccr6List' */));
    const Ccr6ListView = LazyImport(() => import('../pages/Ccr6ListView'/* webpackChunkName: 'ccr6ListView' */));
    const Ccr6New = LazyImport(() => import('../pages/Ccr6New'/* webpackChunkName: 'ccr6New' */));
    const Ccr6Edit = LazyImport(() => import('../pages/Ccr6Edit'/* webpackChunkName: 'ccr6Edit' */));
    const Ccr6View = LazyImport(() => import('../pages/Ccr6View'/* webpackChunkName: 'ccr6View' */));

    const Ccr8List = LazyImport(() => import('../pages/Ccr8List'/* webpackChunkName: 'ccr8List' */));
    const Ccr8ListView = LazyImport(() => import('../pages/Ccr8ListView'/* webpackChunkName: 'ccr8ListView' */));
    const Ccr8New = LazyImport(() => import('../pages/Ccr8New'/* webpackChunkName: 'ccr8New' */));
    const Ccr8Edit = LazyImport(() => import('../pages/Ccr8Edit'/* webpackChunkName: 'ccr8Edit' */));
    const Ccr8View = LazyImport(() => import('../pages/Ccr8View'/* webpackChunkName: 'ccr8View' */));

    const SecaList = LazyImport(() => import('../pages/SecaList'/* webpackChunkName: 'secaList' */));
    const SecaListView = LazyImport(() => import('../pages/SecaListView'/* webpackChunkName: 'secaListView' */));
    const SecaNew = LazyImport(() => import('../pages/SecaNew'/* webpackChunkName: 'secaNew' */));
    const SecaEdit = LazyImport(() => import('../pages/SecaEdit'/* webpackChunkName: 'secaEdit' */));
    const SecaView = LazyImport(() => import('../pages/SecaView'/* webpackChunkName: 'secaView' */));

    const Sec1List = LazyImport(() => import('../pages/Sec1List'/* webpackChunkName: 'sec1List' */));
    const Sec1ListView = LazyImport(() => import('../pages/Sec1ListView'/* webpackChunkName: 'sec1ListView' */));
    const Sec1New = LazyImport(() => import('../pages/Sec1New'/* webpackChunkName: 'sec1New' */));
    const Sec1Edit = LazyImport(() => import('../pages/Sec1Edit'/* webpackChunkName: 'sec1Edit' */));
    const Sec1View = LazyImport(() => import('../pages/Sec1View'/* webpackChunkName: 'sec1View' */));

    const Sec2List = LazyImport(() => import('../pages/Sec2List'/* webpackChunkName: 'sec2List' */));
    const Sec2ListView = LazyImport(() => import('../pages/Sec2ListView'/* webpackChunkName: 'sec2ListView' */));
    const Sec2New = LazyImport(() => import('../pages/Sec2New'/* webpackChunkName: 'sec2New' */));
    const Sec2Edit = LazyImport(() => import('../pages/Sec2Edit'/* webpackChunkName: 'sec2Edit' */));
    const Sec2View = LazyImport(() => import('../pages/Sec2View'/* webpackChunkName: 'sec2View' */));

    const Sec3List = LazyImport(() => import('../pages/Sec3List'/* webpackChunkName: 'sec3List' */));
    const Sec3ListView = LazyImport(() => import('../pages/Sec3ListView'/* webpackChunkName: 'sec3ListView' */));
    const Sec3New = LazyImport(() => import('../pages/Sec3New'/* webpackChunkName: 'sec3New' */));
    const Sec3Edit = LazyImport(() => import('../pages/Sec3Edit'/* webpackChunkName: 'sec3Edit' */));
    const Sec3View = LazyImport(() => import('../pages/Sec3View'/* webpackChunkName: 'sec3View' */));

    const Sec4List = LazyImport(() => import('../pages/Sec4List'/* webpackChunkName: 'sec4List' */));
    const Sec4ListView = LazyImport(() => import('../pages/Sec4ListView'/* webpackChunkName: 'sec4ListView' */));
    const Sec4New = LazyImport(() => import('../pages/Sec4New'/* webpackChunkName: 'sec4New' */));
    const Sec4Edit = LazyImport(() => import('../pages/Sec4Edit'/* webpackChunkName: 'sec4Edit' */));
    const Sec4View = LazyImport(() => import('../pages/Sec4View'/* webpackChunkName: 'sec4View' */));

    const MraList = LazyImport(() => import('../pages/MraList'/* webpackChunkName: 'mraList' */));
    const MraListView = LazyImport(() => import('../pages/MraListView'/* webpackChunkName: 'mraListView' */));
    const MraNew = LazyImport(() => import('../pages/MraNew'/* webpackChunkName: 'mraNew' */));
    const MraEdit = LazyImport(() => import('../pages/MraEdit'/* webpackChunkName: 'mraEdit' */));
    const MraView = LazyImport(() => import('../pages/MraView'/* webpackChunkName: 'mraView' */));

    const Mr1List = LazyImport(() => import('../pages/Mr1List'/* webpackChunkName: 'mr1List' */));
    const Mr1ListView = LazyImport(() => import('../pages/Mr1ListView'/* webpackChunkName: 'mr1ListView' */));
    const Mr1New = LazyImport(() => import('../pages/Mr1New'/* webpackChunkName: 'mr1New' */));
    const Mr1Edit = LazyImport(() => import('../pages/Mr1Edit'/* webpackChunkName: 'mr1Edit' */));
    const Mr1View = LazyImport(() => import('../pages/Mr1View'/* webpackChunkName: 'mr1Edit' */));

    const MrbList = LazyImport(() => import('../pages/MrbList'/* webpackChunkName: 'mrbList' */));
    const MrbListView = LazyImport(() => import('../pages/MrbListView'/* webpackChunkName: 'mrbListView' */));
    const MrbNew = LazyImport(() => import('../pages/MrbNew'/* webpackChunkName: 'mrbNew' */));
    const MrbEdit = LazyImport(() => import('../pages/MrbEdit'/* webpackChunkName: 'mrbEdit' */));
    const MrbView = LazyImport(() => import('../pages/MrbView'/* webpackChunkName: 'mrbView' */));

    const Mr2List = LazyImport(() => import('../pages/Mr2List'/* webpackChunkName: 'mr2List' */));
    const Mr2ListView = LazyImport(() => import('../pages/Mr2ListView'/* webpackChunkName: 'mr2ListView' */));
    const Mr2New = LazyImport(() => import('../pages/Mr2New'/* webpackChunkName: 'mr2New' */));
    const Mr2Edit = LazyImport(() => import('../pages/Mr2Edit'/* webpackChunkName: 'mr2Edit' */));
    const Mr2View = LazyImport(() => import('../pages/Mr2View'/* webpackChunkName: 'mr2View' */));

    const Mr3List = LazyImport(() => import('../pages/Mr3List'/* webpackChunkName: 'mr3List' */));
    const Mr3ListView = LazyImport(() => import('../pages/Mr3ListView'/* webpackChunkName: 'mr3ListView' */));
    const Mr3New = LazyImport(() => import('../pages/Mr3New'/* webpackChunkName: 'mr3New' */));
    const Mr3Edit = LazyImport(() => import('../pages/Mr3Edit'/* webpackChunkName: 'mr3Edit' */));
    const Mr3View = LazyImport(() => import('../pages/Mr3View'/* webpackChunkName: 'mr3View' */));

    const Mr4List = LazyImport(() => import('../pages/Mr4List'/* webpackChunkName: 'mr4List' */));
    const Mr4ListView = LazyImport(() => import('../pages/Mr4ListView'/* webpackChunkName: 'mr4ListView' */));
    const Mr4New = LazyImport(() => import('../pages/Mr4New'/* webpackChunkName: 'mr4New' */));
    const Mr4Edit = LazyImport(() => import('../pages/Mr4Edit'/* webpackChunkName: 'mr4Edit' */));
    const Mr4View = LazyImport(() => import('../pages/Mr4View'/* webpackChunkName: 'mr4View' */));

    const IrrbbaList = LazyImport(() => import('../pages/IrrbbaList'/* webpackChunkName: 'irrbbaList' */));
    const IrrbbaListView = LazyImport(() => import('../pages/IrrbbaListView'/* webpackChunkName: 'irrbbaListView' */));
    const IrrbbaNew = LazyImport(() => import('../pages/IrrbbaNew'/* webpackChunkName: 'irrbbaNew' */));
    const IrrbbaEdit = LazyImport(() => import('../pages/IrrbbaEdit'/* webpackChunkName: 'irrbbaEdit' */));
    const IrrbbaView = LazyImport(() => import('../pages/IrrbbaView'/* webpackChunkName: 'irrbbaView' */));

    const Irrbb1List = LazyImport(() => import('../pages/Irrbb1List'/* webpackChunkName: 'irrbb1List' */));
    const Irrbb1ListView = LazyImport(() => import('../pages/Irrbb1ListView'/* webpackChunkName: 'irrbb1ListView' */));
    const Irrbb1New = LazyImport(() => import('../pages/Irrbb1New'/* webpackChunkName: 'irrbb1New' */));
    const Irrbb1Edit = LazyImport(() => import('../pages/Irrbb1Edit'/* webpackChunkName: 'irrbb1Edit' */));
    const Irrbb1View = LazyImport(() => import('../pages/Irrbb1View'/* webpackChunkName: 'irrbb1View' */));

    const RemaList = LazyImport(() => import('../pages/RemaList'/* webpackChunkName: 'remaList' */));
    const RemaListView = LazyImport(() => import('../pages/RemaListView'/* webpackChunkName: 'remaListView' */));
    const RemaNew = LazyImport(() => import('../pages/RemaNew'/* webpackChunkName: 'remaNew' */));
    const RemaEdit = LazyImport(() => import('../pages/RemaEdit'/* webpackChunkName: 'remaEdit' */));
    const RemaView = LazyImport(() => import('../pages/RemaView'/* webpackChunkName: 'remaView' */));

    const Rem1List = LazyImport(() => import('../pages/Rem1List'/* webpackChunkName: 'rem1List' */));
    const Rem1ListView = LazyImport(() => import('../pages/Rem1ListView'/* webpackChunkName: 'rem1ListView' */));
    const Rem1New = LazyImport(() => import('../pages/Rem1New'/* webpackChunkName: 'rem1New' */));
    const Rem1Edit = LazyImport(() => import('../pages/Rem1Edit'/* webpackChunkName: 'rem1Edit' */));
    const Rem1View = LazyImport(() => import('../pages/Rem1View'/* webpackChunkName: 'rem1View' */));

    const Rem2List = LazyImport(() => import('../pages/Rem2List'/* webpackChunkName: 'rem2List' */));
    const Rem2ListView = LazyImport(() => import('../pages/Rem2ListView'/* webpackChunkName: 'rem2ListView' */));
    const Rem2New = LazyImport(() => import('../pages/Rem2New'/* webpackChunkName: 'rem2New' */));
    const Rem2Edit = LazyImport(() => import('../pages/Rem2Edit'/* webpackChunkName: 'rem2Edit' */));
    const Rem2View = LazyImport(() => import('../pages/Rem2View'/* webpackChunkName: 'rem2View' */));

    const Rem3List = LazyImport(() => import('../pages/Rem3List'/* webpackChunkName: 'rem3List' */));
    const Rem3ListView = LazyImport(() => import('../pages/Rem3ListView'/* webpackChunkName: 'rem3ListView' */));
    const Rem3New = LazyImport(() => import('../pages/Rem3New'/* webpackChunkName: 'rem3New' */));
    const Rem3Edit = LazyImport(() => import('../pages/Rem3Edit'/* webpackChunkName: 'rem3Edit' */));
    const Rem3View = LazyImport(() => import('../pages/Rem3View'/* webpackChunkName: 'rem3View' */));

    const TaxaList = LazyImport(() => import('../pages/TaxaList'/* webpackChunkName: 'taxaList' */));
    const TaxaNew = LazyImport(() => import('../pages/TaxaNew'/* webpackChunkName: 'taxaNew' */));
    const TaxaEdit = LazyImport(() => import('../pages/TaxaEdit'/* webpackChunkName: 'taxaEdit' */));
    const TaxaUltimoList = LazyImport(() => import('../pages/TaxaUltimoList'/* webpackChunkName: 'taxaUltimoList' */));

    const SetSettings = LazyImport(() => import('../pages/SetSettings'/* webpackChunkName: 'setSettings' */));
    const DatasetSettings = LazyImport(() => import('../pages/DatasetSettings'/* webpackChunkName: 'datasetSettings' */));

    const OrganizationSettings = LazyImport(() => import('../pages/OrganizationSettings'/* webpackChunkName: 'organizationSettings' */));
    const EmailSettings = LazyImport(() => import('../pages/EmailSettings'/* webpackChunkName: 'emailSettings' */));
    const UserSettings = LazyImport(() => import('../pages/UserSettings'/* webpackChunkName: 'userSettings' */));
    const UserSecurity = LazyImport(() => import('../pages/UserSecurity'/* webpackChunkName: 'userSecurity' */));
    const UsersSettings = LazyImport(() => import('../pages/UsersSettings'/* webpackChunkName: 'usersSettings' */));
    const UsersNew = LazyImport(() => import('../pages/UsersNew'/* webpackChunkName: 'usersNew' */));
    const UsersDetails = LazyImport(() => import('../pages/UsersDetails'/* webpackChunkName: 'usersDetails' */));
    const UsersEdit = LazyImport(() => import('../pages/UsersEdit'/* webpackChunkName: 'usersEdit' */));

    const RolesSettings = LazyImport(() => import('../pages/RolesSettings'/* webpackChunkName: 'rolesSettings' */));
    const RolesDetails = LazyImport(() => import('../pages/RolesDetails'/* webpackChunkName: 'rolesDetails' */));
    const RolesEdit = LazyImport(() => import('../pages/RolesEdit'/* webpackChunkName: 'rolesEdit' */));
    const RolesNew = LazyImport(() => import('../pages/RolesNew'/* webpackChunkName: 'rolesNew' */));

    const BranchList = LazyImport(() => import('../pages/BranchList'/* webpackChunkName: 'branchList' */));
    const BranchImport = LazyImport(() => import('../pages/BranchImport'/* webpackChunkName: 'branchImport' */));
    const BranchNew = LazyImport(() => import('../pages/BranchNew'/* webpackChunkName: 'branchNew' */));
    const BranchEdit = LazyImport(() => import('../pages/BranchEdit'/* webpackChunkName: 'branchEdit' */));
    const BranchView = LazyImport(() => import('../pages/BranchView'/* webpackChunkName: 'branchView' */));

    const AgenteSaqueList = LazyImport(() => import('../pages/AgenteSaqueList'/* webpackChunkName: 'agenteSaqueList' */));
    const AgenteSaqueNew = LazyImport(() => import('../pages/AgenteSaqueNew'/* webpackChunkName: 'agenteSaqueNew' */));
    const AgenteSaqueEdit = LazyImport(() => import('../pages/AgenteSaqueEdit'/* webpackChunkName: 'agenteSaqueEdit' */));

    const DocumentList = LazyImport(() => import('../pages/DocumentList'/* webpackChunkName: 'document' */));
    const DocumentNew = LazyImport(() => import('../pages/DocumentNew'/* webpackChunkName: 'documentNew' */));
    const DocumentEdit = LazyImport(() => import('../pages/DocumentEdit'/* webpackChunkName: 'documentEdit' */));

    const DocumentBaseDateNew = LazyImport(() => import('../pages/DocumentBaseDateNew'/* webpackChunkName: 'documentBaseDateNew' */));
    const DocumentBaseDateDetails = LazyImport(() => import('../pages/DocumentBaseDateDetails'/* webpackChunkName: 'documentBaseDateDetails' */));

    const DocumentBaseDateBalanceSheet = LazyImport(() => import('../pages/DocumentBaseDateBalanceSheet'/* webpackChunkName: 'documentBaseDateBalanceSheet' */));
    const DocumentBaseDateIncomeStatement = LazyImport(() => import('../pages/DocumentBaseDateIncomeStatement'/* webpackChunkName: 'documentBaseDateIncomeStatement' */));
    const DocumentBaseDateIncomeStatementEmbracing = LazyImport(() => import('../pages/DocumentBaseDateIncomeStatementEmbracing'/* webpackChunkName: 'documentBaseDateIncomeStatementEmbracing' */));
    const DocumentBaseDateCashFlowStatement = LazyImport(() => import('../pages/DocumentBaseDateCashFlowStatement'/* webpackChunkName: 'documentBaseDateCashFlowStatement' */));
    const DocumentBaseDateEquityChangeStatement = LazyImport(() => import('../pages/DocumentBaseDateEquityChangeStatement'/* webpackChunkName: 'documentBaseDateEquityChangeStatement' */));

    const AccountPlanTypeSettings = LazyImport(() => import('../pages/AccountPlanTypeSettings'/* webpackChunkName: 'accountPlanTypeSettings' */));
    const AccountPlanTypeEdit = LazyImport(() => import('../pages/AccountPlanTypeEdit'/* webpackChunkName: 'accountPlanTypeEdit' */));

    return (
        <Switch>
            <PrivateRoute exact path="/specifications" component={Specifications} />

            <Route exact path="/login" component={SignIn} />
            <Route exact path="/register" component={SignUp} />

            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/change-password-challenge" component={ChangePasswordChallenge} />

            <PrivateRoute path="/settings/organization/dataset/:id" component={SetSettings} permission="view_dataset" />
            <PrivateRoute path="/settings/organization/dataset" component={DatasetSettings} permission="view_dataset" />

            <PrivateRoute path="/settings/organization/users/:id/edit" component={UsersEdit} permission="update_users" />
            <PrivateRoute path="/settings/organization/users/new" component={UsersNew} permission="create_users" />
            <PrivateRoute path="/settings/organization/users/:id" component={UsersDetails} permission="view_users" />
            <PrivateRoute path="/settings/organization/users" component={UsersSettings} permission="view_users" />

            <PrivateRoute path="/settings/organization/roles/:id/edit" component={RolesEdit} permission="update_roles" />
            <PrivateRoute path="/settings/organization/roles/new" component={RolesNew} permission="create_roles" />
            <PrivateRoute path="/settings/organization/roles/:id" component={RolesDetails} permission="view_roles" />
            <PrivateRoute path="/settings/organization/roles" component={RolesSettings} permission="view_roles" />

            <PrivateRoute path="/settings/organization/account-plan-type/:id" component={AccountPlanTypeEdit} permission="view_accountPlan" />
            <PrivateRoute path="/settings/organization/account-plan-type" component={AccountPlanTypeSettings} permission="view_accountPlan" />

            <PrivateRoute path="/settings/organization/email" component={EmailSettings} permission="view_email" />

            <PrivateRoute path="/settings/organization" component={OrganizationSettings} permission="view_organization" />
            <PrivateRoute path="/settings/account/details" component={UserSettings} />
            <PrivateRoute path="/settings/account/security" component={UserSecurity} />

            <PrivateRoute path="/specifications/branches/import" component={BranchImport} />
            <PrivateRoute path="/specifications/branches/new" component={BranchNew} permission="create_serviceChannel" />
            <PrivateRoute path="/specifications/branches/:id" component={BranchEdit} permission="view_serviceChannel" />
            <PrivateRoute path="/specifications/branches" component={BranchList} permission="view_serviceChannel" />

            <PrivateRoute path="/specifications/agentes-saque/new" component={AgenteSaqueNew} permission="create_agentesSaque" />
            <PrivateRoute path="/specifications/agentes-saque/:id" component={AgenteSaqueEdit} permission="update_agentesSaque" />
            <PrivateRoute path="/specifications/agentes-saque" component={AgenteSaqueList} permission="view_agentesSaque" />

            <PrivateRoute path="/specifications/pilar3/resources/km1/new" component={Km1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/km1/edit/:id" component={Km1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/km1" component={Km1List} permission="view_pilar3" />
            <Route path="/pilar3/km1/view/:id" component={Km1View} isView />
            <Route path="/pilar3/km1" component={Km1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/ova/new" component={OvaNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ova/edit/:id" component={OvaEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ova" component={OvaList} permission="view_pilar3" />
            <Route path="/pilar3/ova/view/:id" component={OvaView} isView />
            <Route path="/pilar3/ova" component={OvaListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/ov1/new" component={Ov1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ov1/edit/:id" component={Ov1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ov1" component={Ov1List} permission="view_pilar3" />
            <Route path="/pilar3/ov1/view/:id" component={Ov1View} isView />
            <Route path="/pilar3/ov1" component={Ov1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/lia/new" component={LiaNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/lia/edit/:id" component={LiaEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/lia" component={LiaList} permission="view_pilar3" />
            <Route path="/pilar3/lia/view/:id" component={LiaView} isView />
            <Route path="/pilar3/lia" component={LiaListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/li1/new" component={Li1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/li1/edit/:id" component={Li1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/li1" component={Li1List} permission="view_pilar3" />
            <Route path="/pilar3/li1/view/:id" component={Li1View} isView />
            <Route path="/pilar3/li1" component={Li1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/li2/new" component={Li2New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/li2/edit/:id" component={Li2Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/li2" component={Li2List} permission="view_pilar3" />
            <Route path="/pilar3/li2/view/:id" component={Li2View} isView />
            <Route path="/pilar3/li2" component={Li2ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/pv1/new" component={Pv1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/pv1/edit/:id" component={Pv1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/pv1" component={Pv1List} permission="view_pilar3" />
            <Route path="/pilar3/pv1/view/:id" component={Pv1View} isView />
            <Route path="/pilar3/pv1" component={Pv1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/cca/new" component={CcaNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cca/edit/:id" component={CcaEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cca" component={CcaList} permission="view_pilar3" />
            <Route path="/pilar3/cca/view/:id" component={CcaView} isView />
            <Route path="/pilar3/cca" component={CcaListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/cc1/new" component={Cc1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cc1/edit/:id" component={Cc1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cc1" component={Cc1List} permission="view_pilar3" />
            <Route path="/pilar3/cc1/view/:id" component={Cc1View} isView />
            <Route path="/pilar3/cc1" component={Cc1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/cc2/new" component={Cc2New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cc2/edit/:id" component={Cc2Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cc2" component={Cc2List} permission="view_pilar3" />
            <Route path="/pilar3/cc2/view/:id" component={Cc2View} isView />
            <Route path="/pilar3/cc2" component={Cc2ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/gsib1/new" component={Gsib1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/gsib1/edit/:id" component={Gsib1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/gsib1" component={Gsib1List} permission="view_pilar3" />
            <Route path="/pilar3/gsib1/view/:id" component={Gsib1View} isView />
            <Route path="/pilar3/gsib1" component={Gsib1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/ccyb1/new" component={Ccyb1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccyb1/edit/:id" component={Ccyb1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccyb1" component={Ccyb1List} permission="view_pilar3" />
            <Route path="/pilar3/ccyb1/view/:id" component={Ccyb1View} isView />
            <Route path="/pilar3/ccyb1" component={Ccyb1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/lr1/new" component={Lr1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/lr1/edit/:id" component={Lr1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/lr1" component={Lr1List} permission="view_pilar3" />
            <Route path="/pilar3/lr1/view/:id" component={Lr1View} isView />
            <Route path="/pilar3/lr1" component={Lr1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/lr2/new" component={Lr2New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/lr2/edit/:id" component={Lr2Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/lr2" component={Lr2List} permission="view_pilar3" />
            <Route path="/pilar3/lr2/view/:id" component={Lr2View} isView />
            <Route path="/pilar3/lr2" component={Lr2ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/liqa/new" component={LiqaNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/liqa/edit/:id" component={LiqaEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/liqa" component={LiqaList} permission="view_pilar3" />
            <Route path="/pilar3/liqa/view/:id" component={LiqaView} isView />
            <Route path="/pilar3/liqa" component={LiqaListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/liq1/new" component={Liq1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/liq1/edit/:id" component={Liq1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/liq1" component={Liq1List} permission="view_pilar3" />
            <Route path="/pilar3/liq1/view/:id" component={Liq1View} isView />
            <Route path="/pilar3/liq1" component={Liq1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/liq2/new" component={Liq2New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/liq2/edit/:id" component={Liq2Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/liq2" component={Liq2List} permission="view_pilar3" />
            <Route path="/pilar3/liq2/view/:id" component={Liq2View} isView />
            <Route path="/pilar3/liq2" component={Liq2ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/cra/new" component={CraNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cra/edit/:id" component={CraEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cra" component={CraList} permission="view_pilar3" />
            <Route path="/pilar3/cra/view/:id" component={CraView} isView />
            <Route path="/pilar3/cra" component={CraListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/cr1/new" component={Cr1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr1/edit/:id" component={Cr1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr1" component={Cr1List} permission="view_pilar3" />
            <Route path="/pilar3/cr1/view/:id" component={Cr1View} isView />
            <Route path="/pilar3/cr1" component={Cr1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/cr2/new" component={Cr2New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr2/edit/:id" component={Cr2Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr2" component={Cr2List} permission="view_pilar3" />
            <Route path="/pilar3/cr2/view/:id" component={Cr2View} isView />
            <Route path="/pilar3/cr2" component={Cr2ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/crb/new" component={CrbNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/crb/edit/:id" component={CrbEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/crb" component={CrbList} permission="view_pilar3" />
            <Route path="/pilar3/crb/view/:id" component={CrbView} isView />
            <Route path="/pilar3/crb" component={CrbListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/crc/new" component={CrcNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/crc/edit/:id" component={CrcEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/crc" component={CrcList} permission="view_pilar3" />
            <Route path="/pilar3/crc/view/:id" component={CrcView} isView />
            <Route path="/pilar3/crc" component={CrcListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/cr3/new" component={Cr3New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr3/edit/:id" component={Cr3Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr3" component={Cr3List} permission="view_pilar3" />
            <Route path="/pilar3/cr3/view/:id" component={Cr3View} isView />
            <Route path="/pilar3/cr3" component={Cr3ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/cr4/new" component={Cr4New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr4/edit/:id" component={Cr4Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr4" component={Cr4List} permission="view_pilar3" />
            <Route path="/pilar3/cr4/view/:id" component={Cr4View} isView />
            <Route path="/pilar3/cr4" component={Cr4ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/cr5/new" component={Cr5New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr5/edit/:id" component={Cr5Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/cr5" component={Cr5List} permission="view_pilar3" />
            <Route path="/pilar3/cr5/view/:id" component={Cr5View} isView />
            <Route path="/pilar3/cr5" component={Cr5ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/ccra/new" component={CcraNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccra/edit/:id" component={CcraEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccra" component={CcraList} permission="view_pilar3" />
            <Route path="/pilar3/ccra/view/:id" component={CcraView} isView />
            <Route path="/pilar3/ccra" component={CcraListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/ccr1/new" component={Ccr1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr1/edit/:id" component={Ccr1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr1" component={Ccr1List} permission="view_pilar3" />
            <Route path="/pilar3/ccr1/view/:id" component={Ccr1View} isView />
            <Route path="/pilar3/ccr1" component={Ccr1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/ccr3/new" component={Ccr3New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr3/edit/:id" component={Ccr3Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr3" component={Ccr3List} permission="view_pilar3" />
            <Route path="/pilar3/ccr3/view/:id" component={Ccr3View} isView />
            <Route path="/pilar3/ccr3" component={Ccr3ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/ccr5/new" component={Ccr5New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr5/edit/:id" component={Ccr5Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr5" component={Ccr5List} permission="view_pilar3" />
            <Route path="/pilar3/ccr5/view/:id" component={Ccr5View} isView />
            <Route path="/pilar3/ccr5" component={Ccr5ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/ccr6/new" component={Ccr6New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr6/edit/:id" component={Ccr6Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr6" component={Ccr6List} permission="view_pilar3" />
            <Route path="/pilar3/ccr6/view/:id" component={Ccr6View} isView />
            <Route path="/pilar3/ccr6" component={Ccr6ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/ccr8/new" component={Ccr8New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr8/edit/:id" component={Ccr8Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/ccr8" component={Ccr8List} permission="view_pilar3" />
            <Route path="/pilar3/ccr8/view/:id" component={Ccr8View} isView />
            <Route path="/pilar3/ccr8" component={Ccr8ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/seca/new" component={SecaNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/seca/edit/:id" component={SecaEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/seca" component={SecaList} permission="view_pilar3" />
            <Route path="/pilar3/seca/view/:id" component={SecaView} isView />
            <Route path="/pilar3/seca" component={SecaListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/sec1/new" component={Sec1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/sec1/edit/:id" component={Sec1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/sec1" component={Sec1List} permission="view_pilar3" />
            <Route path="/pilar3/sec1/view/:id" component={Sec1View} isView />
            <Route path="/pilar3/sec1" component={Sec1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/sec2/new" component={Sec2New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/sec2/edit/:id" component={Sec2Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/sec2" component={Sec2List} permission="view_pilar3" />
            <Route path="/pilar3/sec2/view/:id" component={Sec2View} isView />
            <Route path="/pilar3/sec2" component={Sec2ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/sec3/new" component={Sec3New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/sec3/edit/:id" component={Sec3Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/sec3" component={Sec3List} permission="view_pilar3" />
            <Route path="/pilar3/sec3/view/:id" component={Sec3View} isView />
            <Route path="/pilar3/sec3" component={Sec3ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/sec4/new" component={Sec4New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/sec4/edit/:id" component={Sec4Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/sec4" component={Sec4List} permission="view_pilar3" />
            <Route path="/pilar3/sec4/view/:id" component={Sec4View} isView />
            <Route path="/pilar3/sec4" component={Sec4ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/mra/new" component={MraNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mra/edit/:id" component={MraEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mra" component={MraList} permission="view_pilar3" />
            <Route path="/pilar3/mra/view/:id" component={MraView} isView />
            <Route path="/pilar3/mra" component={MraListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/mr1/new" component={Mr1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mr1/edit/:id" component={Mr1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mr1" component={Mr1List} permission="view_pilar3" />
            <Route path="/pilar3/mr1/view/:id" component={Mr1View} isView />
            <Route path="/pilar3/mr1" component={Mr1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/mrb/new" component={MrbNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mrb/edit/:id" component={MrbEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mrb" component={MrbList} permission="view_pilar3" />
            <Route path="/pilar3/mrb/view/:id" component={MrbView} isView />
            <Route path="/pilar3/mrb" component={MrbListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/mr2/new" component={Mr2New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mr2/edit/:id" component={Mr2Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mr2" component={Mr2List} permission="view_pilar3" />
            <Route path="/pilar3/mr2/view/:id" component={Mr2View} isView />
            <Route path="/pilar3/mr2" component={Mr2ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/mr3/new" component={Mr3New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mr3/edit/:id" component={Mr3Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mr3" component={Mr3List} permission="view_pilar3" />
            <Route path="/pilar3/mr3/view/:id" component={Mr3View} isView />
            <Route path="/pilar3/mr3" component={Mr3ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/mr4/new" component={Mr4New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mr4/edit/:id" component={Mr4Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/mr4" component={Mr4List} permission="view_pilar3" />
            <Route path="/pilar3/mr4/view/:id" component={Mr4View} isView />
            <Route path="/pilar3/mr4" component={Mr4ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/irrbba/new" component={IrrbbaNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/irrbba/edit/:id" component={IrrbbaEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/irrbba" component={IrrbbaList} permission="view_pilar3" />
            <Route path="/pilar3/irrbba/view/:id" component={IrrbbaView} isView />
            <Route path="/pilar3/irrbba" component={IrrbbaListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/irrbb1/new" component={Irrbb1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/irrbb1/edit/:id" component={Irrbb1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/irrbb1" component={Irrbb1List} permission="view_pilar3" />
            <Route path="/pilar3/irrbb1/view/:id" component={Irrbb1View} isView />
            <Route path="/pilar3/irrbb1" component={Irrbb1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/rema/new" component={RemaNew} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/rema/edit/:id" component={RemaEdit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/rema" component={RemaList} permission="view_pilar3" />
            <Route path="/pilar3/rema/view/:id" component={RemaView} isView />
            <Route path="/pilar3/rema" component={RemaListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/rem1/new" component={Rem1New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/rem1/edit/:id" component={Rem1Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/rem1" component={Rem1List} permission="view_pilar3" />
            <Route path="/pilar3/rem1/view/:id" component={Rem1View} isView />
            <Route path="/pilar3/rem1" component={Rem1ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/rem2/new" component={Rem2New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/rem2/edit/:id" component={Rem2Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/rem2" component={Rem2List} permission="view_pilar3" />
            <Route path="/pilar3/rem2/view/:id" component={Rem2View} isView />
            <Route path="/pilar3/rem2" component={Rem2ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources/rem3/new" component={Rem3New} permission="create_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/rem3/edit/:id" component={Rem3Edit} permission="view_pilar3" />
            <PrivateRoute path="/specifications/pilar3/resources/rem3" component={Rem3List} permission="view_pilar3" />
            <Route path="/pilar3/rem3/view/:id" component={Rem3View} isView />
            <Route path="/pilar3/rem3" component={Rem3ListView} isView />

            <PrivateRoute path="/specifications/pilar3/resources" component={Pilar3} permission="view_pilar3" />
            <Route path="/pilar3" component={Pilar3View} isView />

            <PrivateRoute path="/specifications/cartoes/resources/taxa/new" component={TaxaNew} permission="create_cards" />
            <PrivateRoute path="/specifications/cartoes/resources/taxa/edit/:id" component={TaxaEdit} permission="view_cards" />
            <PrivateRoute path="/specifications/cartoes/resources/taxa/ultimo" component={TaxaUltimoList} permission="view_cards" />
            <PrivateRoute path="/specifications/cartoes/resources/taxa" component={TaxaList} permission="view_cards" />

            <PrivateRoute path="/specifications/cartoes/resources" component={Cartoes} permission="view_cards" />

            <PrivateRoute path="/specifications/demonstrativo/resources/document/new" component={DocumentNew} permission="create_financialStatements" />
            <PrivateRoute path="/specifications/demonstrativo/resources/document/:id/base-date/new" component={DocumentBaseDateNew} permission="create_financialStatements" />

            <PrivateRoute path="/specifications/demonstrativo/resources/document/:documentId/base-date/:baseDateId/balance-sheet" component={DocumentBaseDateBalanceSheet} permission="view_financialStatements" />
            <PrivateRoute path="/specifications/demonstrativo/resources/document/:documentId/base-date/:baseDateId/income-statement" component={DocumentBaseDateIncomeStatement} permission="view_financialStatements" />
            <PrivateRoute path="/specifications/demonstrativo/resources/document/:documentId/base-date/:baseDateId/income-statement-embracing" component={DocumentBaseDateIncomeStatementEmbracing} permission="view_financialStatements" />
            <PrivateRoute path="/specifications/demonstrativo/resources/document/:documentId/base-date/:baseDateId/cash-flow-statement" component={DocumentBaseDateCashFlowStatement} permission="view_financialStatements" />
            <PrivateRoute path="/specifications/demonstrativo/resources/document/:documentId/base-date/:baseDateId/equity-change-statement" component={DocumentBaseDateEquityChangeStatement} permission="view_financialStatements" />

            <PrivateRoute path="/specifications/demonstrativo/resources/document/:documentId/base-date/:baseDateId" component={DocumentBaseDateDetails} permission="view_financialStatements" />
            <PrivateRoute path="/specifications/demonstrativo/resources/document/:id" component={DocumentEdit} permission="view_financialStatements" />
            <PrivateRoute path="/specifications/demonstrativo/resources/document" component={DocumentList} permission="view_financialStatements" />

            <Route path="/branches" component={BranchView} isView />

            <Redirect from="/" to="/specifications" />
        </Switch>
    )
}