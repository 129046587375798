export function saveMraRequest(data) {
  return {
    type: '@mra/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveMraSuccess() {
  return {
    type: '@mra/SAVE_SUCCESS',
  }
}

export function saveMraFailure() {
  return {
    type: '@mra/SAVE_FAILURE',
  }
}

export function updateMraRequest(data) {
  return {
    type: '@mra/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateMraSuccess() {
  return {
    type: '@mra/UPDATE_SUCCESS',
  }
}

export function updateMraFailure() {
  return {
    type: '@mra/UPDATE_FAILURE',
  }
}