export function saveIrrbbaRequest(data) {
  return {
    type: '@irrbba/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveIrrbbaSuccess() {
  return {
    type: '@irrbba/SAVE_SUCCESS',
  }
}

export function saveIrrbbaFailure() {
  return {
    type: '@irrbba/SAVE_FAILURE',
  }
}

export function updateIrrbbaRequest(data) {
  return {
    type: '@irrbba/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateIrrbbaSuccess() {
  return {
    type: '@irrbba/UPDATE_SUCCESS',
  }
}

export function updateIrrbbaFailure() {
  return {
    type: '@irrbba/UPDATE_FAILURE',
  }
}