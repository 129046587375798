export function saveCcr6Request(data) {
  return {
    type: '@ccr6/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCcr6Success() {
  return {
    type: '@ccr6/SAVE_SUCCESS',
  }
}

export function saveCcr6Failure() {
  return {
    type: '@ccr6/SAVE_FAILURE',
  }
}

export function updateCcr6Request(data) {
  return {
    type: '@ccr6/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCcr6Success() {
  return {
    type: '@ccr6/UPDATE_SUCCESS',
  }
}

export function updateCcr6Failure() {
  return {
    type: '@ccr6/UPDATE_FAILURE',
  }
}