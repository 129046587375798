export function saveIncomeStatementRequest(data) {
  return {
    type: '@incomeStatement/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveIncomeStatementSuccess() {
  return {
    type: '@incomeStatement/SAVE_SUCCESS',
  }
}

export function saveIncomeStatementFailure() {
  return {
    type: '@incomeStatement/SAVE_FAILURE',
  }
}