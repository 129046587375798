import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveSecaFailure, saveSecaSuccess, updateSecaFailure, updateSecaSuccess } from './actions';

export function* saveSeca({ payload }) {
  try {
    yield call(api.post, 'api/seca', payload.data, { withCredentials: true });

    yield put(saveSecaSuccess());

    toast.success('SECA criado com sucesso!')

    history.push('/specifications/pilar3/resources/seca');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveSecaFailure());
  }
}

export function* updateSeca({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/seca/${id}`, payload.data, { withCredentials: true });

    yield put(updateSecaSuccess());

    toast.success('SECA atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/seca');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateSecaFailure());
  }
}

export default all([
  takeLatest('@seca/SAVE_REQUEST', saveSeca),
  takeLatest('@seca/UPDATE_REQUEST', updateSeca)
]);