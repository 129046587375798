export function saveTaxaRequest(data) {
  return {
    type: '@taxa/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveTaxaSuccess() {
  return {
    type: '@taxa/SAVE_SUCCESS',
  }
}

export function saveTaxaFailure() {
  return {
    type: '@taxa/SAVE_FAILURE',
  }
}

export function updateTaxaRequest(data) {
  return {
    type: '@taxa/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateTaxaSuccess() {
  return {
    type: '@taxa/UPDATE_SUCCESS',
  }
}

export function updateTaxaFailure() {
  return {
    type: '@taxa/UPDATE_FAILURE',
  }
}