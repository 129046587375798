export function saveCashFlowStatementRequest(data) {
  return {
    type: '@cashFlowStatement/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCashFlowStatementSuccess() {
  return {
    type: '@cashFlowStatement/SAVE_SUCCESS',
  }
}

export function saveCashFlowStatementFailure() {
  return {
    type: '@cashFlowStatement/SAVE_FAILURE',
  }
}