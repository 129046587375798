export function saveCcyb1Request(data) {
  return {
    type: '@ccyb1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCcyb1Success() {
  return {
    type: '@ccyb1/SAVE_SUCCESS',
  }
}

export function saveCcyb1Failure() {
  return {
    type: '@ccyb1/SAVE_FAILURE',
  }
}

export function updateCcyb1Request(data) {
  return {
    type: '@ccyb1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCcyb1Success() {
  return {
    type: '@ccyb1/UPDATE_SUCCESS',
  }
}

export function updateCcyb1Failure() {
  return {
    type: '@ccyb1/UPDATE_FAILURE',
  }
}