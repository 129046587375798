export function updateEmailRequest(data) {
  return {
    type: '@email/UPDATE_REQUEST',
    payload: { data },
  };
}

export function updateEmailSuccess(email) {
  return {
    type: '@email/UPDATE_SUCCESS',
    payload: { email },
  };
}

export function updateEmailFailure() {
  return {
    type: '@email/UPDATE_FAILURE',
  };
}