export function updateSetRequest(data) {
  return {
    type: '@set/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateSetSuccess() {
  return {
    type: '@set/UPDATE_SUCCESS',
  }
}

export function updateSetFailure() {
  return {
    type: '@set/UPDATE_FAILURE',
  }
}