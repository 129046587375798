import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCcaFailure, saveCcaSuccess, updateCcaFailure, updateCcaSuccess } from './actions';

export function* saveCca({ payload }) {
  try {
    yield call(api.post, 'api/cca', payload.data, { withCredentials: true });

    yield put(saveCcaSuccess());

    toast.success('Cca criado com sucesso!')

    history.push('/specifications/pilar3/resources/cca');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCcaFailure());
  }
}

export function* updateCca({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/cca/${id}`, payload.data, { withCredentials: true });

    yield put(updateCcaSuccess());

    toast.success('Cca atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/cca');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCcaFailure());
  }
}

export default all([
  takeLatest('@cca/SAVE_REQUEST', saveCca),
  takeLatest('@cca/UPDATE_REQUEST', updateCca)
]);