import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { saveIncomeStatementFailure, saveIncomeStatementSuccess } from './actions';

export function* saveIncomeStatement({ payload }) {
  try {
    yield call(api.post, `api/income-statement`, payload.data, { withCredentials: true });

    yield put(saveIncomeStatementSuccess());

    toast.success('Demonstração do resultado atualizado com sucesso!');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveIncomeStatementFailure());
  }
}

export default all([
  takeLatest('@incomeStatement/SAVE_REQUEST', saveIncomeStatement),
]);