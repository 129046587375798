import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveMraFailure, saveMraSuccess, updateMraFailure, updateMraSuccess } from './actions';

export function* saveMra({ payload }) {
  try {
    yield call(api.post, 'api/mra', payload.data, { withCredentials: true });

    yield put(saveMraSuccess());

    toast.success('MRA criado com sucesso!')

    history.push('/specifications/pilar3/resources/mra');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveMraFailure());
  }
}

export function* updateMra({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/mra/${id}`, payload.data, { withCredentials: true });

    yield put(updateMraSuccess());

    toast.success('MRA atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/mra');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateMraFailure());
  }
}

export default all([
  takeLatest('@mra/SAVE_REQUEST', saveMra),
  takeLatest('@mra/UPDATE_REQUEST', updateMra)
]);