export function saveCcraRequest(data) {
  return {
    type: '@ccra/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCcraSuccess() {
  return {
    type: '@ccra/SAVE_SUCCESS',
  }
}

export function saveCcraFailure() {
  return {
    type: '@ccra/SAVE_FAILURE',
  }
}

export function updateCcraRequest(data) {
  return {
    type: '@ccra/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCcraSuccess() {
  return {
    type: '@ccra/UPDATE_SUCCESS',
  }
}

export function updateCcraFailure() {
  return {
    type: '@ccra/UPDATE_FAILURE',
  }
}