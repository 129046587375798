import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCc2Failure, saveCc2Success, updateCc2Failure, updateCc2Success } from './actions';

export function* saveCc2({ payload }) {
  try {
    yield call(api.post, 'api/cc2', payload.data, { withCredentials: true });

    yield put(saveCc2Success());

    toast.success('CC2 criado com sucesso!')

    history.push('/specifications/pilar3/resources/cc2');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCc2Failure());
  }
}

export function* updateCc2({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/cc2/${id}`, payload.data, { withCredentials: true });

    yield put(updateCc2Success());

    toast.success('CC2 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/cc2');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCc2Failure());
  }
}

export default all([
  takeLatest('@cc2/SAVE_REQUEST', saveCc2),
  takeLatest('@cc2/UPDATE_REQUEST', updateCc2)
]);