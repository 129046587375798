import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveLiaFailure, saveLiaSuccess, updateLiaFailure, updateLiaSuccess } from './actions';

export function* saveLia({ payload }) {
  try {
    yield call(api.post, 'api/lia', payload.data, { withCredentials: true });

    yield put(saveLiaSuccess());

    toast.success('LIA criado com sucesso!')

    history.push('/specifications/pilar3/resources/lia');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveLiaFailure());
  }
}

export function* updateLia({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/lia/${id}`, payload.data, { withCredentials: true });

    yield put(updateLiaSuccess());

    toast.success('LIA atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/lia');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateLiaFailure());
  }
}

export default all([
  takeLatest('@lia/SAVE_REQUEST', saveLia),
  takeLatest('@lia/UPDATE_REQUEST', updateLia)
]);