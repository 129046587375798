export function saveDocumentRequest(data) {
  return {
    type: '@document/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveDocumentSuccess() {
  return {
    type: '@document/SAVE_SUCCESS',
  }
}

export function saveDocumentFailure() {
  return {
    type: '@document/SAVE_FAILURE',
  }
}

export function updateDocumentRequest(data) {
  return {
    type: '@document/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateDocumentSuccess() {
  return {
    type: '@document/UPDATE_SUCCESS',
  }
}

export function updateDocumentFailure() {
  return {
    type: '@document/UPDATE_FAILURE',
  }
}