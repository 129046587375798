import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCr4Failure, saveCr4Success, updateCr4Failure, updateCr4Success } from './actions';

export function* saveCr4({ payload }) {
  try {
    yield call(api.post, 'api/cr4', payload.data, { withCredentials: true });

    yield put(saveCr4Success());

    toast.success('CR4 criado com sucesso!')

    history.push('/specifications/pilar3/resources/cr4');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCr4Failure());
  }
}

export function* updateCr4({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/cr4/${id}`, payload.data, { withCredentials: true });

    yield put(updateCr4Success());

    toast.success('CR4 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/cr4');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCr4Failure());
  }
}

export default all([
  takeLatest('@cr4/SAVE_REQUEST', saveCr4),
  takeLatest('@cr4/UPDATE_REQUEST', updateCr4)
]);