export function saveLiaRequest(data) {
  return {
    type: '@lia/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveLiaSuccess() {
  return {
    type: '@lia/SAVE_SUCCESS',
  }
}

export function saveLiaFailure() {
  return {
    type: '@lia/SAVE_FAILURE',
  }
}

export function updateLiaRequest(data) {
  return {
    type: '@lia/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateLiaSuccess() {
  return {
    type: '@lia/UPDATE_SUCCESS',
  }
}

export function updateLiaFailure() {
  return {
    type: '@lia/UPDATE_FAILURE',
  }
}