export function updateUserRequest(data) {
  return {
    type: '@user/UPDATE_REQUEST',
    payload: { data },
  };
}

export function updateUserSuccess(profile) {
  return {
    type: '@user/UPDATE_SUCCESS',
    payload: { profile },
  };
}

export function updateUserFailure() {
  return {
    type: '@user/UPDATE_FAILURE',
  };
}

export function updateUserPasswordRequest(data) {
  return {
    type: '@user/UPDATE_PASSWORD_REQUEST',
    payload: { data },
  };
}

export function updateUserPasswordSuccess() {
  return {
    type: '@user/UPDATE_PASSWORD_SUCCESS',
  };
}

export function updateUserPasswordFailure() {
  return {
    type: '@user/UPDATE_PASSWORD_FAILURE',
  };
}

export function updateUserRoleRequest(data) {
  return {
    type: '@user/UPDATE_ROLE_REQUEST',
    payload: { data },
  };
}

export function updateUserRoleSuccess() {
  return {
    type: '@user/UPDATE_ROLE_SUCCESS',
  };
}

export function updateUserRoleFailure() {
  return {
    type: '@user/UPDATE_ROLE_FAILURE',
  };
}