export function saveLr2Request(data) {
  return {
    type: "@lr2/SAVE_REQUEST",
    payload: { data }
  };
}

export function saveLr2Success() {
  return {
    type: "@lr2/SAVE_SUCCESS"
  };
}

export function saveLr2Failure() {
  return {
    type: "@lr2/SAVE_FAILURE"
  };
}

export function updateLr2Request(data) {
  return {
    type: "@lr2/UPDATE_REQUEST",
    payload: { data }
  };
}

export function updateLr2Success() {
  return {
    type: "@lr2/UPDATE_SUCCESS"
  };
}

export function updateLr2Failure() {
  return {
    type: "@lr2/UPDATE_FAILURE"
  };
}
