import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveOvaFailure, saveOvaSuccess, updateOvaFailure, updateOvaSuccess } from './actions';

export function* saveOva({ payload }) {
  try {
    yield call(api.post, 'api/ova', payload.data, { withCredentials: true });

    yield put(saveOvaSuccess());

    toast.success('Ova criado com sucesso!')

    history.push('/specifications/pilar3/resources/ova');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveOvaFailure());
  }
}

export function* updateOva({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/ova/${id}`, payload.data, { withCredentials: true });

    yield put(updateOvaSuccess());

    toast.success('Ova atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/ova');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateOvaFailure());
  }
}

export default all([
  takeLatest('@ova/SAVE_REQUEST', saveOva),
  takeLatest('@ova/UPDATE_REQUEST', updateOva)
]);