export function saveMr2Request(data) {
  return {
    type: '@mr2/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveMr2Success() {
  return {
    type: '@mr2/SAVE_SUCCESS',
  }
}

export function saveMr2Failure() {
  return {
    type: '@mr2/SAVE_FAILURE',
  }
}

export function updateMr2Request(data) {
  return {
    type: '@mr2/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateMr2Success() {
  return {
    type: '@mr2/UPDATE_SUCCESS',
  }
}

export function updateMr2Failure() {
  return {
    type: '@mr2/UPDATE_FAILURE',
  }
}