export function updateOrganizationRequest(data) {
  return {
    type: '@organization/UPDATE_REQUEST',
    payload: { data },
  };
}

export function updateOrganizationSuccess(organization) {
  return {
    type: '@organization/UPDATE_SUCCESS',
    payload: { organization },
  };
}

export function updateOrganizationFailure() {
  return {
    type: '@organization/UPDATE_FAILURE',
  };
}