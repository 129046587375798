import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCcr1Failure, saveCcr1Success, updateCcr1Failure, updateCcr1Success } from './actions';

export function* saveCcr1({ payload }) {
  try {
    yield call(api.post, 'api/ccr1', payload.data, { withCredentials: true });

    yield put(saveCcr1Success());

    toast.success('CCR1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/ccr1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCcr1Failure());
  }
}

export function* updateCcr1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/ccr1/${id}`, payload.data, { withCredentials: true });

    yield put(updateCcr1Success());

    toast.success('CCR1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/ccr1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCcr1Failure());
  }
}

export default all([
  takeLatest('@ccr1/SAVE_REQUEST', saveCcr1),
  takeLatest('@ccr1/UPDATE_REQUEST', updateCcr1)
]);