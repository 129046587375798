import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveMr2Failure, saveMr2Success, updateMr2Failure, updateMr2Success } from './actions';

export function* saveMr2({ payload }) {
  try {
    yield call(api.post, 'api/mr2', payload.data, { withCredentials: true });

    yield put(saveMr2Success());

    toast.success('MR2 criado com sucesso!')

    history.push('/specifications/pilar3/resources/mr2');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveMr2Failure());
  }
}

export function* updateMr2({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/mr2/${id}`, payload.data, { withCredentials: true });

    yield put(updateMr2Success());

    toast.success('MR2 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/mr2');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateMr2Failure());
  }
}

export default all([
  takeLatest('@mr2/SAVE_REQUEST', saveMr2),
  takeLatest('@mr2/UPDATE_REQUEST', updateMr2)
]);