export function saveBranchRequest(data) {
  return {
    type: '@branch/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveBranchSuccess() {
  return {
    type: '@branch/SAVE_SUCCESS',
  }
}

export function saveBranchFailure() {
  return {
    type: '@branch/SAVE_FAILURE',
  }
}

export function updateBranchRequest(data) {
  return {
    type: '@branch/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateBranchSuccess() {
  return {
    type: '@branch/UPDATE_SUCCESS',
  }
}

export function updateBranchFailure() {
  return {
    type: '@branch/UPDATE_FAILURE',
  }
}