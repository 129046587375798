import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveKm1Failure, saveKm1Success, updateKm1Failure, updateKm1Success } from './actions';

export function* saveKm1({ payload }) {
  try {
    yield call(api.post, 'api/km1', payload.data, { withCredentials: true });

    yield put(saveKm1Success());

    toast.success('Km1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/km1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveKm1Failure());
  }
}

export function* updateKm1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/km1/${id}`, payload.data, { withCredentials: true });

    yield put(updateKm1Success());

    toast.success('Km1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/km1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateKm1Failure());
  }
}

export default all([
  takeLatest('@km1/SAVE_KM1_REQUEST', saveKm1),
  takeLatest('@km1/UPDATE_KM1_REQUEST', updateKm1)
]);