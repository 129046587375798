export function saveCr5Request(data) {
  return {
    type: '@cr5/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCr5Success() {
  return {
    type: '@cr5/SAVE_SUCCESS',
  }
}

export function saveCr5Failure() {
  return {
    type: '@cr5/SAVE_FAILURE',
  }
}

export function updateCr5Request(data) {
  return {
    type: '@cr5/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCr5Success() {
  return {
    type: '@cr5/UPDATE_SUCCESS',
  }
}

export function updateCr5Failure() {
  return {
    type: '@cr5/UPDATE_FAILURE',
  }
}