import styled from 'styled-components';
import backgroundPattern from '~/assets/bg-pattern.png';

export const Wrapper = styled.div`
    height: 100%;
    background: #f9f9fa url(${backgroundPattern}) repeat;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Box = styled.div`
    width: 100%;
    max-width: 400px;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 1px 4px rgba(0,0,0,.1);
    border-radius: 4px;
    display: flex;
`;

export const AuthSidebar = styled.div`
    width: 60px;
    background: #564f64;
    background-image: linear-gradient(-180deg,rgba(52,44,62,0),rgba(52,44,62,.5));
    box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
    border-radius: 4px 0 0 4px;
    margin-top: -1px;
    margin-bottom: -1px;
    text-align: center;

    img {
        margin-top: 20px;
    }
`;

export const Content = styled.div`
    width: 340px;
    padding: 20px 40px 40px 40px;

    h2 {
        text-align: center;
        margin-bottom: 30px;
    }

    form {
        display: flex;
        flex-direction: column;

        label {
            font-weight: bold;
            font-size: 14px;
            margin: 0 0 5px 0;
        }

        input {
            margin: 0 0 10px;
            box-shadow: inset 0 2px 0 rgba(0,0,0,.04);
            height: auto;
            border: 1px solid #c9c0d1;
            padding: 8px 12px 7px;
            position: relative;
            border-radius: 3px;
            color: #493e54;
        }

        span {
            color: #C72516;
            font-weight: bold;
        }

        button {
            height: 44px;
            background: #6c5fc7;
            font-weight: bold;
            color: #fff;
            border: 1px solid #413496;
            border-radius: 4px;
            font-size: 14px;

            &:hover:enabled {
                background: #5b4cc0;
            }

            &:disabled {
                cursor: not-allowed;
            }
        }

        a {
            color: #968BA0;
            font-size: 14px;
            margin: 0 0 10px;

            &:hover {
                color: #493e54;
            }
        }
    }
`;