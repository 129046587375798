import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCc1Failure, saveCc1Success, updateCc1Failure, updateCc1Success } from './actions';

export function* saveCc1({ payload }) {
  try {
    yield call(api.post, 'api/cc1', payload.data, { withCredentials: true });

    yield put(saveCc1Success());

    toast.success('CC1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/cc1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCc1Failure());
  }
}

export function* updateCc1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/cc1/${id}`, payload.data, { withCredentials: true });

    yield put(updateCc1Success());

    toast.success('CC1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/cc1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCc1Failure());
  }
}

export default all([
  takeLatest('@cc1/SAVE_REQUEST', saveCc1),
  takeLatest('@cc1/UPDATE_REQUEST', updateCc1)
]);