export function saveCcaRequest(data) {
  return {
    type: '@cca/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCcaSuccess() {
  return {
    type: '@cca/SAVE_SUCCESS',
  }
}

export function saveCcaFailure() {
  return {
    type: '@cca/SAVE_FAILURE',
  }
}

export function updateCcaRequest(data) {
  return {
    type: '@cca/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCcaSuccess() {
  return {
    type: '@cca/UPDATE_SUCCESS',
  }
}

export function updateCcaFailure() {
  return {
    type: '@cca/UPDATE_FAILURE',
  }
}