import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import AuthLayout from '~/pages/_layouts/auth';
import ViewLayout from '~/pages/_layouts/view';

export default function RouteWrapper({
    component: Component,
    isView,
    ...rest
}) {
    let Layout = isView
        ? ViewLayout
        : AuthLayout;

    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    )
}

RouteWrapper.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}

RouteWrapper.defaultProps = {
    isView: false
}