export function saveCcr1Request(data) {
  return {
    type: '@ccr1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCcr1Success() {
  return {
    type: '@ccr1/SAVE_SUCCESS',
  }
}

export function saveCcr1Failure() {
  return {
    type: '@ccr1/SAVE_FAILURE',
  }
}

export function updateCcr1Request(data) {
  return {
    type: '@ccr1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCcr1Success() {
  return {
    type: '@ccr1/UPDATE_SUCCESS',
  }
}

export function updateCcr1Failure() {
  return {
    type: '@ccr1/UPDATE_FAILURE',
  }
}