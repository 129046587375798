export function saveMr3Request(data) {
  return {
    type: '@mr3/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveMr3Success() {
  return {
    type: '@mr3/SAVE_SUCCESS',
  }
}

export function saveMr3Failure() {
  return {
    type: '@mr3/SAVE_FAILURE',
  }
}

export function updateMr3Request(data) {
  return {
    type: '@mr3/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateMr3Success() {
  return {
    type: '@mr3/UPDATE_SUCCESS',
  }
}

export function updateMr3Failure() {
  return {
    type: '@mr3/UPDATE_FAILURE',
  }
}