export function saveLi2Request(data) {
  return {
    type: '@li2/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveLi2Success() {
  return {
    type: '@li2/SAVE_SUCCESS',
  }
}

export function saveLi2Failure() {
  return {
    type: '@li2/SAVE_FAILURE',
  }
}

export function updateLi2Request(data) {
  return {
    type: '@li2/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateLi2Success() {
  return {
    type: '@li2/UPDATE_SUCCESS',
  }
}

export function updateLi2Failure() {
  return {
    type: '@li2/UPDATE_FAILURE',
  }
}