export function saveCc1Request(data) {
  return {
    type: '@cc1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCc1Success() {
  return {
    type: '@cc1/SAVE_SUCCESS',
  }
}

export function saveCc1Failure() {
  return {
    type: '@cc1/SAVE_FAILURE',
  }
}

export function updateCc1Request(data) {
  return {
    type: '@cc1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCc1Success() {
  return {
    type: '@cc1/UPDATE_SUCCESS',
  }
}

export function updateCc1Failure() {
  return {
    type: '@cc1/UPDATE_FAILURE',
  }
}