export function saveAgenteSaqueRequest(data) {
  return {
    type: '@agenteSaque/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveAgenteSaqueSuccess() {
  return {
    type: '@agenteSaque/SAVE_SUCCESS',
  }
}

export function saveAgenteSaqueFailure() {
  return {
    type: '@agenteSaque/SAVE_FAILURE',
  }
}

export function updateAgenteSaqueRequest(data) {
  return {
    type: '@agenteSaque/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateAgenteSaqueSuccess() {
  return {
    type: '@agenteSaque/UPDATE_SUCCESS',
  }
}

export function updateAgenteSaqueFailure() {
  return {
    type: '@agenteSaque/UPDATE_FAILURE',
  }
}