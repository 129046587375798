export function saveRem2Request(data) {
  return {
    type: '@rem2/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveRem2Success() {
  return {
    type: '@rem2/SAVE_SUCCESS',
  }
}

export function saveRem2Failure() {
  return {
    type: '@rem2/SAVE_FAILURE',
  }
}

export function updateRem2Request(data) {
  return {
    type: '@rem2/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateRem2Success() {
  return {
    type: '@rem2/UPDATE_SUCCESS',
  }
}

export function updateRem2Failure() {
  return {
    type: '@rem2/UPDATE_FAILURE',
  }
}