export function saveMr4Request(data) {
  return {
    type: '@mr4/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveMr4Success() {
  return {
    type: '@mr4/SAVE_SUCCESS',
  }
}

export function saveMr4Failure() {
  return {
    type: '@mr4/SAVE_FAILURE',
  }
}

export function updateMr4Request(data) {
  return {
    type: '@mr4/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateMr4Success() {
  return {
    type: '@mr4/UPDATE_SUCCESS',
  }
}

export function updateMr4Failure() {
  return {
    type: '@mr4/UPDATE_FAILURE',
  }
}