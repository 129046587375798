export function saveCr2Request(data) {
  return {
    type: '@cr2/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCr2Success() {
  return {
    type: '@cr2/SAVE_SUCCESS',
  }
}

export function saveCr2Failure() {
  return {
    type: '@cr2/SAVE_FAILURE',
  }
}

export function updateCr2Request(data) {
  return {
    type: '@cr2/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCr2Success() {
  return {
    type: '@cr2/UPDATE_SUCCESS',
  }
}

export function updateCr2Failure() {
  return {
    type: '@cr2/UPDATE_FAILURE',
  }
}