import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { savePv1Failure, savePv1Success, updatePv1Failure, updatePv1Success } from './actions';

export function* savePv1({ payload }) {
  try {
    yield call(api.post, 'api/pv1', payload.data, { withCredentials: true });

    yield put(savePv1Success());

    toast.success('PV1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/pv1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(savePv1Failure());
  }
}

export function* updatePv1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/pv1/${id}`, payload.data, { withCredentials: true });

    yield put(updatePv1Success());

    toast.success('PV1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/pv1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updatePv1Failure());
  }
}

export default all([
  takeLatest('@pv1/SAVE_REQUEST', savePv1),
  takeLatest('@pv1/UPDATE_REQUEST', updatePv1)
]);