import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import history from "~/services/history";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Unauthorized() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Container component="main" className={classes.main} maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom>403</Typography>
                <Typography variant="body1">Você não possui autorização para acessar está página.</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => history.goBack()}
                >
                    Voltar
                </Button>
            </Container>
        </div>
    );
}