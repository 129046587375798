export function saveMrbRequest(data) {
  return {
    type: '@mrb/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveMrbSuccess() {
  return {
    type: '@mrb/SAVE_SUCCESS',
  }
}

export function saveMrbFailure() {
  return {
    type: '@mrb/SAVE_FAILURE',
  }
}

export function updateMrbRequest(data) {
  return {
    type: '@mrb/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateMrbSuccess() {
  return {
    type: '@mrb/UPDATE_SUCCESS',
  }
}

export function updateMrbFailure() {
  return {
    type: '@mrb/UPDATE_FAILURE',
  }
}