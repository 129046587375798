import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';

// import Sidebar from '~/components/Sidebar';

import { signOut } from '~/store/modules/auth/actions';

import {
    App,
    Sidebar, SidebarSectionGroup, SidebarSection, SidebarPrimaryItems,
    SidebarItem, SidebarItemWrapper, SidebarItemIcon, SidebarItemLabel,
    TextOverflow,
    SidebarDropdownRoot, OrgAndUserWrapper, TextOverflowOrg, TextOverflowUser,
    Wrapper, WrapperColumn, SidebarDropdownActor, SidebarAvatar,
    SidebarDropdownMenu, OrgSummary, Details, SummaryOrgName, MenuItemLink, Divider,
    UserSummary, StyledUserBadge, StyledNameAndEmail
} from './styles';

import { ReactComponent as SpecificationIcon } from '~/assets/specification.svg';
import { ReactComponent as SettingIcon } from '~/assets/settings.svg';

import { can } from '~/services/can';

export default function DefaultLayout({ children }) {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.user.profile);
    const [showMenu, setShowMenu] = useState(false);
    const dropdownMenu = useRef(null);

    const showDropdownMenu = () => { setShowMenu(true); }

    const hideDropdownMenu = (event) => {
        //if (!dropdownMenu.current.contains(event.target)) {
        setShowMenu(false);
        //}
    }

    useEffect(() => {
        if (!showMenu) {
            return;
        }

        document.addEventListener('click', hideDropdownMenu);

        return () => {
            document.removeEventListener('click', hideDropdownMenu);
        }
    }, [showMenu]);

    function handleSignOut() {
        dispatch(signOut());
    }

    return (
        <App>
            <Sidebar>
                <SidebarSectionGroup>
                    <SidebarSection>
                        <SidebarDropdownRoot>
                            <SidebarDropdownActor onClick={showDropdownMenu}>
                                <SidebarAvatar>
                                    <Avatar
                                        name={profile.organization.name}
                                        maxInitials={2}
                                        size="32"
                                        round="5px"
                                        textSizeRatio={2}
                                        style={{ fontWeight: "bold" }}
                                    />
                                </SidebarAvatar>
                                <OrgAndUserWrapper>
                                    <TextOverflowOrg>{profile.organization.name}</TextOverflowOrg>
                                    <TextOverflowUser>{profile.name}</TextOverflowUser>
                                </OrgAndUserWrapper>
                            </SidebarDropdownActor>
                            {showMenu &&
                                <SidebarDropdownMenu ref={dropdownMenu}>
                                    <OrgSummary>
                                        <Avatar
                                            name={profile.organization.name}
                                            maxInitials={2}
                                            size="36"
                                            round="5px"
                                            textSizeRatio={1}
                                            style={{ fontWeight: "bold" }}
                                        />
                                        <Details>
                                            <SummaryOrgName>
                                                {profile.organization.name}
                                            </SummaryOrgName>
                                        </Details>
                                    </OrgSummary>
                                    <Divider />
                                    <UserSummary>
                                        <StyledUserBadge>
                                            <Avatar
                                                name={profile.name}
                                                maxInitials={2}
                                                size="32"
                                                round
                                                textSizeRatio={1}
                                                style={{ fontWeight: "bold" }}
                                            />
                                        </StyledUserBadge>
                                        <StyledNameAndEmail>
                                            <span>{profile.name}</span>
                                            <div>{profile.email}</div>
                                        </StyledNameAndEmail>
                                    </UserSummary>
                                    <div>
                                        <MenuItemLink to="/settings/account/details">
                                            <span>Editar perfil</span>
                                        </MenuItemLink>
                                        <MenuItemLink onClick={handleSignOut}>
                                            <span>Sair</span>
                                        </MenuItemLink>
                                    </div>
                                </SidebarDropdownMenu>
                            }
                        </SidebarDropdownRoot>
                    </SidebarSection>
                    <SidebarPrimaryItems>
                        <SidebarSection>
                            {can("view", "specifications") && (
                                <SidebarItem to="/specifications">
                                    <SidebarItemWrapper>
                                        <SidebarItemIcon>
                                            <SpecificationIcon fill="currentColor" width="20px" height="20px" />
                                        </SidebarItemIcon>
                                        <SidebarItemLabel>
                                            <TextOverflow>
                                                Especificações
                                        </TextOverflow>
                                        </SidebarItemLabel>
                                    </SidebarItemWrapper>
                                </SidebarItem>
                            )}

                            {can("view", "settings") && (
                                <SidebarItem to="/settings/organization">
                                    <SidebarItemWrapper>
                                        <SidebarItemIcon>
                                            <SettingIcon fill="currentColor" width="20px" height="20px" />
                                        </SidebarItemIcon>
                                        <SidebarItemLabel>
                                            <TextOverflow>
                                                Configurações
                                        </TextOverflow>
                                        </SidebarItemLabel>
                                    </SidebarItemWrapper>
                                </SidebarItem>
                            )}
                        </SidebarSection>
                    </SidebarPrimaryItems>
                </SidebarSectionGroup>
            </Sidebar>
            <Wrapper>
                <WrapperColumn>
                    {children}
                </WrapperColumn>
            </Wrapper>
        </App>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.element.isRequired,
}