export function saveRem1Request(data) {
  return {
    type: '@rem1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveRem1Success() {
  return {
    type: '@rem1/SAVE_SUCCESS',
  }
}

export function saveRem1Failure() {
  return {
    type: '@rem1/SAVE_FAILURE',
  }
}

export function updateRem1Request(data) {
  return {
    type: '@rem1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateRem1Success() {
  return {
    type: '@rem1/UPDATE_SUCCESS',
  }
}

export function updateRem1Failure() {
  return {
    type: '@rem1/UPDATE_FAILURE',
  }
}