export function saveKm1Request(data) {
  return {
    type: '@km1/SAVE_KM1_REQUEST',
    payload: { data },
  }
}

export function saveKm1Success() {
  return {
    type: '@km1/SAVE_KM1_SUCCESS',
  }
}

export function saveKm1Failure() {
  return {
    type: '@km1/SAVE_KM1_FAILURE',
  }
}

export function updateKm1Request(data) {
  return {
    type: '@km1/UPDATE_KM1_REQUEST',
    payload: { data },
  }
}

export function updateKm1Success() {
  return {
    type: '@km1/UPDATE_KM1_SUCCESS',
  }
}

export function updateKm1Failure() {
  return {
    type: '@km1/UPDATE_KM1_FAILURE',
  }
}