export function saveCrcRequest(data) {
  return {
    type: '@crc/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCrcSuccess() {
  return {
    type: '@crc/SAVE_SUCCESS',
  }
}

export function saveCrcFailure() {
  return {
    type: '@crc/SAVE_FAILURE',
  }
}

export function updateCrcRequest(data) {
  return {
    type: '@crc/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCrcSuccess() {
  return {
    type: '@crc/UPDATE_SUCCESS',
  }
}

export function updateCrcFailure() {
  return {
    type: '@crc/UPDATE_FAILURE',
  }
}