export function saveBalanceSheetRequest(data) {
  return {
    type: '@balanceSheet/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveBalanceSheetSuccess() {
  return {
    type: '@balanceSheet/SAVE_SUCCESS',
  }
}

export function saveBalanceSheetFailure() {
  return {
    type: '@balanceSheet/SAVE_FAILURE',
  }
}