export function saveCr4Request(data) {
  return {
    type: '@cr4/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCr4Success() {
  return {
    type: '@cr4/SAVE_SUCCESS',
  }
}

export function saveCr4Failure() {
  return {
    type: '@cr4/SAVE_FAILURE',
  }
}

export function updateCr4Request(data) {
  return {
    type: '@cr4/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCr4Success() {
  return {
    type: '@cr4/UPDATE_SUCCESS',
  }
}

export function updateCr4Failure() {
  return {
    type: '@cr4/UPDATE_FAILURE',
  }
}