import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveIrrbb1Failure, saveIrrbb1Success, updateIrrbb1Failure, updateIrrbb1Success } from './actions';

export function* saveIrrbb1({ payload }) {
  try {
    yield call(api.post, 'api/irrbb1', payload.data, { withCredentials: true });

    yield put(saveIrrbb1Success());

    toast.success('IRRBB1 criado com sucesso!')

    history.push('/specifications/pilar3/resources/irrbb1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveIrrbb1Failure());
  }
}

export function* updateIrrbb1({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/irrbb1/${id}`, payload.data, { withCredentials: true });

    yield put(updateIrrbb1Success());

    toast.success('IRRBB1 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/irrbb1');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateIrrbb1Failure());
  }
}

export default all([
  takeLatest('@irrbb1/SAVE_REQUEST', saveIrrbb1),
  takeLatest('@irrbb1/UPDATE_REQUEST', updateIrrbb1)
]);