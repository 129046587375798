export function saveSec3Request(data) {
  return {
    type: '@sec3/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveSec3Success() {
  return {
    type: '@sec3/SAVE_SUCCESS',
  }
}

export function saveSec3Failure() {
  return {
    type: '@sec3/SAVE_FAILURE',
  }
}

export function updateSec3Request(data) {
  return {
    type: '@sec3/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateSec3Success() {
  return {
    type: '@sec3/UPDATE_SUCCESS',
  }
}

export function updateSec3Failure() {
  return {
    type: '@sec3/UPDATE_FAILURE',
  }
}