export function saveBaseDateRequest(data) {
  return {
    type: '@baseDate/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveBaseDateSuccess() {
  return {
    type: '@baseDate/SAVE_SUCCESS',
  }
}

export function saveBaseDateFailure() {
  return {
    type: '@baseDate/SAVE_FAILURE',
  }
}

export function updateBaseDateRequest(data) {
  return {
    type: '@baseDate/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateBaseDateSuccess() {
  return {
    type: '@baseDate/UPDATE_SUCCESS',
  }
}

export function updateBaseDateFailure() {
  return {
    type: '@baseDate/UPDATE_FAILURE',
  }
}
