import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCraFailure, saveCraSuccess, updateCraFailure, updateCraSuccess } from './actions';

export function* saveCra({ payload }) {
  try {
    yield call(api.post, 'api/cra', payload.data, { withCredentials: true });

    yield put(saveCraSuccess());

    toast.success('CRA criado com sucesso!')

    history.push('/specifications/pilar3/resources/cra');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCraFailure());
  }
}

export function* updateCra({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/cra/${id}`, payload.data, { withCredentials: true });

    yield put(updateCraSuccess());

    toast.success('CRA atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/cra');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCraFailure());
  }
}

export default all([
  takeLatest('@cra/SAVE_REQUEST', saveCra),
  takeLatest('@cra/UPDATE_REQUEST', updateCra)
]);