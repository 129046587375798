export function saveSec1Request(data) {
  return {
    type: '@sec1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveSec1Success() {
  return {
    type: '@sec1/SAVE_SUCCESS',
  }
}

export function saveSec1Failure() {
  return {
    type: '@sec1/SAVE_FAILURE',
  }
}

export function updateSec1Request(data) {
  return {
    type: '@sec1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateSec1Success() {
  return {
    type: '@sec1/UPDATE_SUCCESS',
  }
}

export function updateSec1Failure() {
  return {
    type: '@sec1/UPDATE_FAILURE',
  }
}