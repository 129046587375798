export function saveLiq1Request(data) {
  return {
    type: '@liq1/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveLiq1Success() {
  return {
    type: '@liq1/SAVE_SUCCESS',
  }
}

export function saveLiq1Failure() {
  return {
    type: '@liq1/SAVE_FAILURE',
  }
}

export function updateLiq1Request(data) {
  return {
    type: '@liq1/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateLiq1Success() {
  return {
    type: '@liq1/UPDATE_SUCCESS',
  }
}

export function updateLiq1Failure() {
  return {
    type: '@liq1/UPDATE_FAILURE',
  }
}