export function saveCr3Request(data) {
  return {
    type: '@cr3/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCr3Success() {
  return {
    type: '@cr3/SAVE_SUCCESS',
  }
}

export function saveCr3Failure() {
  return {
    type: '@cr3/SAVE_FAILURE',
  }
}

export function updateCr3Request(data) {
  return {
    type: '@cr3/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCr3Success() {
  return {
    type: '@cr3/UPDATE_SUCCESS',
  }
}

export function updateCr3Failure() {
  return {
    type: '@cr3/UPDATE_FAILURE',
  }
}