export function saveCcr5Request(data) {
  return {
    type: '@ccr5/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCcr5Success() {
  return {
    type: '@ccr5/SAVE_SUCCESS',
  }
}

export function saveCcr5Failure() {
  return {
    type: '@ccr5/SAVE_FAILURE',
  }
}

export function updateCcr5Request(data) {
  return {
    type: '@ccr5/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCcr5Success() {
  return {
    type: '@ccr5/UPDATE_SUCCESS',
  }
}

export function updateCcr5Failure() {
  return {
    type: '@ccr5/UPDATE_FAILURE',
  }
}