export function saveCcr3Request(data) {
  return {
    type: '@ccr3/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveCcr3Success() {
  return {
    type: '@ccr3/SAVE_SUCCESS',
  }
}

export function saveCcr3Failure() {
  return {
    type: '@ccr3/SAVE_FAILURE',
  }
}

export function updateCcr3Request(data) {
  return {
    type: '@ccr3/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateCcr3Success() {
  return {
    type: '@ccr3/UPDATE_SUCCESS',
  }
}

export function updateCcr3Failure() {
  return {
    type: '@ccr3/UPDATE_FAILURE',
  }
}