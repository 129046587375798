export function saveIncomeStatementEmbracingRequest(data) {
  return {
    type: '@incomeStatementEmbracing/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveIncomeStatementEmbracingSuccess() {
  return {
    type: '@incomeStatementEmbracing/SAVE_SUCCESS',
  }
}

export function saveIncomeStatementEmbracingFailure() {
  return {
    type: '@incomeStatementEmbracing/SAVE_FAILURE',
  }
}