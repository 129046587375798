import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveCcraFailure, saveCcraSuccess, updateCcraFailure, updateCcraSuccess } from './actions';

export function* saveCcra({ payload }) {
  try {
    yield call(api.post, 'api/ccra', payload.data, { withCredentials: true });

    yield put(saveCcraSuccess());

    toast.success('CCRA criado com sucesso!')

    history.push('/specifications/pilar3/resources/ccra');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveCcraFailure());
  }
}

export function* updateCcra({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/ccra/${id}`, payload.data, { withCredentials: true });

    yield put(updateCcraSuccess());

    toast.success('CCRA atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/ccra');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateCcraFailure());
  }
}

export default all([
  takeLatest('@ccra/SAVE_REQUEST', saveCcra),
  takeLatest('@ccra/UPDATE_REQUEST', updateCcra)
]);