import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveSec4Failure, saveSec4Success, updateSec4Failure, updateSec4Success } from './actions';

export function* saveSec4({ payload }) {
  try {
    yield call(api.post, 'api/sec4', payload.data, { withCredentials: true });

    yield put(saveSec4Success());

    toast.success('SEC4 criado com sucesso!')

    history.push('/specifications/pilar3/resources/sec4');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveSec4Failure());
  }
}

export function* updateSec4({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/sec4/${id}`, payload.data, { withCredentials: true });

    yield put(updateSec4Success());

    toast.success('SEC4 atualizado com sucesso!')

    history.push('/specifications/pilar3/resources/sec4');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateSec4Failure());
  }
}

export default all([
  takeLatest('@sec4/SAVE_REQUEST', saveSec4),
  takeLatest('@sec4/UPDATE_REQUEST', updateSec4)
]);