import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { saveRoleFailure, saveRoleSuccess, updateRoleFailure, updateRoleSuccess } from './actions';

export function* saveRole({ payload }) {
  try {
    yield call(api.post, 'api/roles', payload.data, { withCredentials: true });

    yield put(saveRoleSuccess());

    toast.success('Perfil de acesso criado com sucesso!')

    history.push('/settings/organization/roles');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(saveRoleFailure());
  }
}

export function* updateRole({ payload }) {
  try {
    const { id } = payload.data;
    yield call(api.post, `api/roles/${id}`, payload.data, { withCredentials: true });

    yield put(updateRoleSuccess());

    toast.success('Perfil de acesso atualizado com sucesso!')

    history.push('/settings/organization/roles');
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else if (error?.message) {
      toast.error(error.message);
    } else {
      toast.error('Ocorreu um erro inesperado. Tente novamente.');
    }
    yield put(updateRoleFailure());
  }
}

export default all([
  takeLatest('@role/SAVE_REQUEST', saveRole),
  takeLatest('@role/UPDATE_REQUEST', updateRole)
]);