export function saveLiq2Request(data) {
  return {
    type: '@liq2/SAVE_REQUEST',
    payload: { data },
  }
}

export function saveLiq2Success() {
  return {
    type: '@liq2/SAVE_SUCCESS',
  }
}

export function saveLiq2Failure() {
  return {
    type: '@liq2/SAVE_FAILURE',
  }
}

export function updateLiq2Request(data) {
  return {
    type: '@liq2/UPDATE_REQUEST',
    payload: { data },
  }
}

export function updateLiq2Success() {
  return {
    type: '@liq2/UPDATE_SUCCESS',
  }
}

export function updateLiq2Failure() {
  return {
    type: '@liq2/UPDATE_FAILURE',
  }
}